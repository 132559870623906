$(document).ready(() => {
  let takeEditSession = '';
  let statusType = 'yes';
  let isEditMode = false;
  let allDataUpdateObj = {};
  let ObjCustom = {};
  let boxCount;

  function triggerEditPopup() {
    $('[data-toggle="tooltipInputTooltip"]').tooltip({
      placement: 'top',
      html: true,
      customClass: 'input-tooltip',
      boundary: 'window',
      delay: 800,
    });
  }

  function updateSaveBtnLastPopup() {
    if ($('.total-reg-values').val() > 20) {
      $('.save-var-data').addClass('pe-none opacity-50');
    } else {
      $('.save-var-data').removeClass('pe-none opacity-50');
    }
  }

  function checkingIfInputEqualListedEditPopup() {
    if ($('.after-edit-allowed-values').children().length
    < parseInt($('.edit-levels select').val(), 10)) {
      $('.save-edit-var-data').removeClass('opacity-50 pe-none');
      $('.btn-sv').removeClass('pe-none opacity-50');
      if ($('.edit-reg-var').val().trim().length <= 0) {
        $('.editable-custom-reg').addClass('pe-none opacity-50');
      }
    } else {
      $('.save-edit-var-data').addClass('opacity-50 pe-none');
      $('.btn-sv').addClass('pe-none opacity-50');
      $('.edit-allowed-values input').attr('disabled', true);
    }
  }

  function calculateEachRegValueAfterEditSave() {
    $('.common-boxes').each(function () {
      if ($(this).find('.level-no').text() === '-') {
        if ($(this).find('.reg-var').text().toLowerCase()
          .trim() === 'age') {
          $(this).find('.total-reg').val(2);
        } else {
          $(this).find('.total-reg').val(1);
        }
      } else {
        $(this).find('.total-reg').val(parseInt($(this).find('.level-no').text(), 10) - 1);
      }
    });
  }

  calculateEachRegValueAfterEditSave();

  function calculateFinalRegVal() {
    let finalRegValue = 0;
    $('.common-boxes').each(function () {
      const eachValue = parseInt($(this).find('.total-reg').val(), 10);
      finalRegValue += eachValue;
    });
    $('.total-reg-values').val(finalRegValue - 10);
    updateSaveBtnLastPopup();
  }

  calculateFinalRegVal();

  function checkStatusType() {
    if (statusType === 'no') {
      $('.choose-var-type .num').addClass('d-none');
      $('.edit-reg-var').addClass('pe-none');
      statusType = 'yes';
    } else {
      $('.choose-var-type .num').removeClass('d-none');
      $('.edit-reg-var').removeClass('pe-none');
    }
  }

  let globalEdit = false;
  let globalBoxesCount = '';
  let pastVarType = '';

  $(document).on('click', '.edit-var', function () {
    globalEdit = true;
    globalBoxesCount = $(this).parents('.each-var-box').attr('data-box');
    $('.edit-allowed-values input').val('');
    $('.which-box').text(globalBoxesCount);
    $('.not-allowed-duplicate').addClass('d-none');
    $('.var-error').addClass('d-none');
    $('.btn-sv').removeClass('pe-none opacity-50');
    isEditMode = true;
    if ($(this).parents('.static-boxes').length > 0) {
      $('.save-edit-var-data').parent().removeClass('d-none');
      $('.editable-custom-reg').parent().addClass('d-none');
    } else {
      $('.save-edit-var-data').parent().addClass('d-none');
      $('.editable-custom-reg').parent().removeClass('d-none');
    }
    $('#customVariablesEdit .modal-title').text('Edit variable');
    $('.edit-reg-var').attr('placeholder', 'Update custom variable*');
    takeEditSession = this;
    $('.edit-reg-var').val($(this).parents('.boxes-container').find('.reg-var').text());

    const getCurrentLevelNo = parseInt($(this).parents('.boxes-container').find('.level-no').text(), 10);
    statusType = $(this).parents('.each-var-box').data('no-change');
    const addedTyped = $(this).parents('.boxes-container').find('.var-type').text()
      .toLowerCase()
      .trim();
    checkStatusType();
    pastVarType = addedTyped;
    if (addedTyped === 'numerical') {
      $('.edit-levels').addClass('d-none');
      $('.edit-allowed-values').addClass('d-none');
      $('.choose-var-type a').removeClass('active pe-none');
      $('.choose-var-type a.num').addClass('active pe-none');
      $('.error-msg-txt').addClass('d-none');
      $('.allowed-text').addClass('d-none');
      $('.after-edit-allowed-values').empty();
    } else {
      $('.edit-levels').removeClass('d-none');
      $('.edit-allowed-values').removeClass('d-none');
      $('.choose-var-type a').removeClass('active pe-none');
      $('.choose-var-type a:last-child').addClass('active pe-none');
      $('.error-msg-txt').removeClass('d-none');
    }

    const arrayAllowedLists = [];
    $('.after-edit-allowed-values').empty();
    $(this).parents('.boxes-container').find('.allowed-values .all-added-values').each(function () {
      arrayAllowedLists.push($(this).find('h6').text().trim());
    });
    for (let i = 0; i < arrayAllowedLists.length; i += 1) {
      $('.after-edit-allowed-values').append(`<div class="d-flex 
        align-items-center after-edited-each-value">
        <p>${arrayAllowedLists[i]}</p><a class="close-allowed-values">&times;</a></div>`);
    }

    if ($('.after-edit-allowed-values').children().length > 0) {
      $('.allowed-text').removeClass('d-none');
    } else {
      $('.allowed-text').addClass('d-none');
    }

    let valueLength = 0;
    let selectOptions = '';
    valueLength = parseInt($(this).parents('.each-var-box').data('select-end-value-dropdown'), 10);
    const initialValue = parseInt($(this).parents('.each-var-box').data('select-initial-value'), 10);
    for (let i = initialValue; i <= valueLength; i += 1) {
      if (getCurrentLevelNo === i) {
        selectOptions += `<option selected>${i}</option>`;
      } else {
        selectOptions += `<option>${i}</option>`;
      }
    }
    $('.edit-levels select').html(selectOptions);

    let totalRegVarAfterSubtract = 0;
    const aboveSiblingsCount = $(this).parents('.each-var-box').prevAll().length;
    let countEachBoxes = 0;
    $('.common-boxes').each(function () {
      if (countEachBoxes < aboveSiblingsCount) {
        const eachValue = parseInt($(this).find('.total-reg').val(), 10);
        totalRegVarAfterSubtract += eachValue;
      }
      countEachBoxes += 1;
    });
    const numberOfOptions = 5;
    if (totalRegVarAfterSubtract > numberOfOptions) {
      $('.edit-levels select option').each(function () {
        const optionValue = parseInt($(this).val(), 10);
        if (optionValue > (30 - totalRegVarAfterSubtract + 1)) {
          $(this).hide();
          $(this).attr('disabled', true);
        }
      });
    }

    if ($(this).parents('.add-custom-var').length > 0) {
      let allRegVarTotal = 0;
      $('.static-boxes .common-boxes').each(function () {
        const eachValue = parseInt($(this).find('.total-reg').val(), 10);
        allRegVarTotal += eachValue;
      });

      let totalRegVarAfterSubtractCustom = 0;
      let countCustomEachBoxes = 0;
      $('.add-custom-var .common-boxes').each(function () {
        if (countCustomEachBoxes < aboveSiblingsCount) {
          const eachValue = parseInt($(this).find('.total-reg').val(), 10);
          totalRegVarAfterSubtractCustom += eachValue;
        }
        countCustomEachBoxes += 1;
      });

      const numberOfOptionsCustom = 5;
      if ((totalRegVarAfterSubtractCustom + allRegVarTotal) > numberOfOptionsCustom) {
        $('.edit-levels select option').each(function () {
          const optionValue = parseInt($(this).val(), 10);
          if (optionValue > (30 - (totalRegVarAfterSubtractCustom + allRegVarTotal) + 1)) {
            $(this).hide();
            $(this).attr('disabled', true);
          }
        });
      }
    }

    const totalReg = $('.total-reg-values').val();
    const options = $('.edit-levels option');
    const totalOptions = $('.edit-levels option').length;

    if (parseInt(totalReg, 10) === 20) {
      const index = $('.edit-levels option:selected').index();
      for (let i = 0; i < totalOptions; i += 1) {
        if (i > index) { // Check to ensure we don't go out of bounds
          $(options[i]).hide();
          $(options[i]).attr('disabled', true);
        }
      }
    } else {
      const total = $('.edit-levels option:selected').index() + (20 - totalReg);
      for (let i = 0; i < parseInt(totalOptions, 10); i += 1) {
        if (i > parseInt(total, 10)) { // Check to ensure we don't go out of bounds
          $(options[i]).hide();
          $(options[i]).attr('disabled', true);
        }
      }
    }

    if ($('.after-edit-allowed-values').children().length
      === parseInt($('.edit-levels select').val(), 10)) {
      $('.edit-allowed-values input').attr('disabled', true);
    } else if ($('.after-edit-allowed-values').children().length
      < parseInt($('.edit-levels select').val(), 10)) {
      $('.edit-allowed-values input').attr('disabled', false);
    } else {
      $('.edit-allowed-values input').attr('disabled', true);
    }
  });

  /* eslint-disable max-len */

  $('.edit-allowed-values input[type="text"]').on('input', function () {
    $(this).val($(this).val().replace(/[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/g, ''));
  });

  $('.edit-reg-var').on('input', function () {
    $(this).val($(this).val().replace(/[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/g, ''));
  });


  $('.edit-allowed-values input[type="text"]').on('keypress', function (e) {
    if (e.which === 13) {
      let isMatch = false;
      $('.allowed-text').removeClass('d-none');
      const inputValue = $(this).val().trim();
      if (inputValue !== '' && inputValue !== null && inputValue !== undefined) {
        $(this).val('');
        $('.after-edit-allowed-values p').each(function () {
          if ($(this).text().toLowerCase().trim() === inputValue.toLowerCase()) {
            isMatch = true;
          }
        });

        if (isMatch) {
          $(this).val('');
          $('.not-allowed-duplicate').removeClass('d-none');
        } else {
          $('.not-allowed-duplicate').addClass('d-none');
          $('.after-edit-allowed-values').append(`${'<div class="d-flex '
            + 'align-items-center after-edited-each-value"><p>'}
            ${inputValue}</p><a class="close-allowed-values">&times;</a></div>`);

          if ($('.after-edit-allowed-values').children().length === parseInt($('.edit-levels select').val(), 10)) {
            $('.edit-allowed-values input').attr('disabled', true);
            if ($('.edit-reg-var').val().trim().length <= 0) {
              $('.btn-sv').addClass('pe-none opacity-50');
            } else {
              let matchFound = false;
              if (globalEdit === false) {
                $('.reg-var').each(function () {
                  if ($(this).text().toLowerCase().trim() === $('.edit-reg-var').val().toLowerCase().trim()) {
                    matchFound = true;
                  }
                });
              } else {
                $('.reg-var').each(function () {
                  if ($(this).text().toLowerCase().trim() === $('.edit-reg-var').val().toLowerCase().trim() && parseInt($('.which-box').text(), 10) !== parseInt($(this).parents('.each-var-box').attr('data-box'), 10)) {
                    matchFound = true;
                  }
                });
              }

              if (!matchFound) {
                $('.btn-sv').removeClass('pe-none opacity-50');
              }
            }
          } else {
            $('.edit-allowed-values input').attr('disabled', false);
          }
        }
      }
    }
  });

  $(document).on('click', '.close-allowed-values', function () {
    $(this).parents('.after-edited-each-value').remove();
    if ($('.after-edit-allowed-values').children().length === parseInt($('.edit-levels select').val(), 10)
    && $('.edit-reg-var').val().trim().length > 0) {
      $('.edit-allowed-values input').attr('disabled', true);
      $('.btn-sv').removeClass('pe-none opacity-50');
    } else {
      $('.edit-allowed-values input').attr('disabled', false);
      $('.btn-sv').addClass('pe-none opacity-50');
    }
  });

  function checkForPlusEnabled() {
    let finalRegValueReached = 0;
    $('.common-boxes').each(function () {
      const eachValue = parseInt($(this).find('.total-reg').val(), 10); // Added radix parameter
      finalRegValueReached += eachValue;
      if (finalRegValueReached >= 30) {
        $('.plus-btn').addClass('pe-none opacity-50');
      } else {
        $('.plus-btn').removeClass('pe-none opacity-50');
      }
    });
    $('.total-reg-values').val(finalRegValueReached - 10);
  }

  $(document).on('click', '.save-edit-var-data', function () {
    if ($('.after-edit-allowed-values').children().length
      === parseInt($(this).parents('.edit-custom-var-values').find('.edit-levels select').val(), 10)) {
      const updatedRegVar = $(this).parents('.edit-custom-var-values').find('.edit-reg-var').val();
      $(takeEditSession).parents('.boxes-container').find('.reg-var').text(updatedRegVar);
      const updatedLevelNo = $(this).parents('.edit-custom-var-values').find('.edit-levels select').val();
      $(takeEditSession).parents('.boxes-container').find('.level-no-section h5').text(updatedLevelNo);
      calculateEachRegValueAfterEditSave();
      calculateFinalRegVal();
      const arrayUpdatedAllowedLists = [];
      $(takeEditSession).parents('.boxes-container').find('.allowed-values').empty();
      $(this).parents('.edit-custom-var-values').find('.after-edited-each-value p').each(function () {
        arrayUpdatedAllowedLists.push($(this).text().trim());
      });
      for (let i = 0; i < arrayUpdatedAllowedLists.length; i += 1) {
        $(takeEditSession).parents('.boxes-container').find('.allowed-values').append(`<div class="d-flex 
          justify-content-center align-items-center 
          all-added-values"><h6 class="level-value">${arrayUpdatedAllowedLists[i]}</h6></div>`);
      }
    }
    calculateEachRegValueAfterEditSave();
    calculateFinalRegVal();
    checkForPlusEnabled();
    triggerEditPopup();
  });


  $('.edit-levels select').on('change', function () {
    const value = parseInt($(this).val(), 10);
    if ($('.after-edit-allowed-values').children().length === parseInt($('.edit-levels select').val(), 10)) {
      $('.edit-allowed-values input').attr('disabled', true);
      let matchFound = false;
      if (globalEdit === false) {
        $('.reg-var').each(function () {
          if ($(this).text().toLowerCase().trim() === $('.edit-reg-var').val().toLowerCase().trim()) {
            matchFound = true;
          }
        });
      } else {
        $('.reg-var').each(function () {
          if ($(this).text().toLowerCase().trim() === $('.edit-reg-var').val().toLowerCase().trim() && parseInt($('.which-box').text(), 10) !== parseInt($(this).parents('.each-var-box').attr('data-box'), 10)) {
            matchFound = true;
          }
        });
      }

      if (!matchFound) {
        $('.btn-sv').removeClass('pe-none opacity-50');
      }
    } else if ($('.after-edit-allowed-values').children().length < parseInt($('.edit-levels select').val(), 10)) {
      $('.edit-allowed-values input').attr('disabled', false);
      $('.btn-sv').addClass('pe-none opacity-50');
    } else if ($('.after-edit-allowed-values').children().length > parseInt($('.edit-levels select').val(), 10)) {
      $('.edit-allowed-values input').attr('disabled', true);
      $('.btn-sv').addClass('pe-none opacity-50');
    }
  });

  $(document).on('click', '.plus-btn .add-small-org-btn', function () {
    $('.not-allowed-duplicate').addClass('d-none');
    $('.var-error').addClass('d-none');
    $('.edit-allowed-values input').val('');
    pastVarType = 'numerical';
    $('.choose-var-type a').removeClass('active pe-none');
    $('.choose-var-type a.num').addClass('active pe-none');
    $('.edit-levels').addClass('d-none');
    $('.edit-allowed-values').addClass('d-none');
    $('.error-msg-txt').addClass('d-none');
    globalEdit = false;

    $('.edit-allowed-values input').attr('disabled', false);
    checkStatusType();
    $('.allowed-text').addClass('d-none');
    $('.edit-reg-var').val('');

    if ($('.edit-reg-var').val().trim().length <= 0) {
      $('.btn-sv').addClass('pe-none opacity-50');
    }

    $('.edit-reg-var').attr('placeholder', 'Add custom variables*');
    $('.save-edit-var-data').parent().addClass('d-none');
    $('.editable-custom-reg').parent().removeClass('d-none');

    $('#customVariablesEdit .modal-title').text('Add custom variables');

    isEditMode = false;
    $('.after-edit-allowed-values').empty();

    const standardChildrenCount = $('.custom-container.static-boxes').find('.each-var-box').children().length;
    let allRegVarTotal = 0;
    if (standardChildrenCount > 0) {
      $('.common-boxes').each(function () {
        const eachValue = parseInt($(this).find('.total-reg').val(), 10);
        allRegVarTotal += eachValue;
      });
    }

    const valueLength = 20;
    let selectOptions = '';
    const initialValue = 2;
    for (let i = initialValue; i <= valueLength; i += 1) {
      selectOptions += `<option>${i}</option>`;
    }
    $('.edit-levels select').html(selectOptions);

    let totalRegVarAfterSubtract = 0;
    const aboveCustomSiblingsCount = $(this).parents('.plus-box').prevAll().length;
    let countCustomEachBoxes = 0;
    $('.common-boxes').each(function () {
      if (countCustomEachBoxes < aboveCustomSiblingsCount) {
        const eachValue = parseInt($(this).find('.total-reg').val(), 10);
        totalRegVarAfterSubtract += eachValue;
      }
      countCustomEachBoxes += 1;
    });

    const numberOfOptions = 5;
    if ((totalRegVarAfterSubtract + allRegVarTotal) > numberOfOptions) {
      $('.edit-levels select option').each(function () {
        const optionValue = parseInt($(this).val(), 10);
        if (optionValue > (30 - (totalRegVarAfterSubtract + allRegVarTotal) + 1)) {
          $(this).hide();
          $(this).attr('disabled', true);
        }
      });
    }
  });

  $('.choose-var-type a').on('click', function () {
    $('.choose-var-type a').removeClass('active pe-none');
    $(this).addClass('active pe-none');
    const childElement = $(this).text().toLowerCase().trim();
    if (childElement === 'numerical') {
      let matchFound = false;
      $('.not-allowed-duplicate').addClass('d-none');
      $('.edit-levels').addClass('d-none');
      $('.edit-allowed-values').addClass('d-none');
      $('.error-msg-txt').addClass('d-none');
      if ($('.edit-reg-var').val().trim().length <= 0) {
        $('.btn-sv').addClass('pe-none opacity-50');
      } else {
        if (globalEdit === false) {
          $('.reg-var').each(function () {
            if ($(this).text().toLowerCase().trim() === $('.edit-reg-var').val().toLowerCase().trim()) {
              matchFound = true;
            }
          });
        } else {
          $('.reg-var').each(function () {
            if ($(this).text().toLowerCase().trim() === $('.edit-reg-var').val().toLowerCase().trim() && parseInt($('.which-box').text(), 10) !== parseInt($(this).parents('.each-var-box').attr('data-box'), 10)) {
              matchFound = true;
            }
          });
        }

        if (!matchFound) {
          $('.btn-sv').removeClass('pe-none opacity-50');
        }
      }
    } else {
      if (pastVarType.toLowerCase().trim() === 'numerical') {
        $('.allowed-text').addClass('d-none');
        $('.after-edit-allowed-values').empty();
        $('.edit-allowed-values input').attr('disabled', false);
      }
      $('.edit-levels').removeClass('d-none');
      $('.edit-allowed-values').removeClass('d-none');
      $('.error-msg-txt').removeClass('d-none');
      if ($('.after-edit-allowed-values').children().length === parseInt($('.edit-levels select').val(), 10)) {
        $('.edit-allowed-values input').attr('disabled', true);
        if ($('.edit-reg-var').val().trim().length <= 0) {
          $('.btn-sv').addClass('pe-none opacity-50');
        } else {
          let matchFound = false;
          if (globalEdit === false) {
            $('.reg-var').each(function () {
              if ($(this).text().toLowerCase().trim() === $('.edit-reg-var').val().toLowerCase().trim()) {
                matchFound = true;
              }
            });
          } else {
            $('.reg-var').each(function () {
              if ($(this).text().toLowerCase().trim() === $('.edit-reg-var').val().toLowerCase().trim() && parseInt($('.which-box').text(), 10) !== parseInt($(this).parents('.each-var-box').attr('data-box'), 10)) {
                matchFound = true;
              }
            });
          }

          if (!matchFound) {
            $('.btn-sv').removeClass('pe-none opacity-50');
          }
        }
      } else {
        $('.edit-allowed-values input').attr('disabled', false);
        $('.btn-sv').addClass('pe-none opacity-50');
      }
    }
  });

  $('.edit-reg-var').on('keyup', function (e) {
    const value = $(this).val();
    let isMatchFound = false;
    $('.var-error').addClass('d-none');
    if ($('.edit-reg-var').val().trim().length > 0) {
      $('.reg-var').each(function (index) {
        if (globalEdit === false) {
          if ($(this).text().toLowerCase().trim() === value.toLowerCase().trim()) {
            $('.btn-sv').addClass('pe-none opacity-50');
            $('.var-error').removeClass('d-none');
            isMatchFound = true;
          }
          if (!$('.edit-allowed-values').hasClass('d-none')) {
            if ($('.after-edit-allowed-values').children().length !== parseInt($('.edit-levels select').val(), 10)) {
              $('.btn-sv').addClass('pe-none opacity-50');
              isMatchFound = true;
            }
          }
        } else if (index !== parseInt($('.which-box').text(), 10) - 1) {
          if ($(this).text().toLowerCase().trim() === value.toLowerCase().trim()) {
            $('.btn-sv').addClass('pe-none opacity-50');
            $('.var-error').removeClass('d-none');
            isMatchFound = true;
          }
          if (!$('.edit-allowed-values').hasClass('d-none')) {
            if ($('.after-edit-allowed-values').children().length !== parseInt($('.edit-levels select').val(), 10)) {
              $('.btn-sv').addClass('pe-none opacity-50');
              isMatchFound = true;
            }
          }
        }
      });
      if (!isMatchFound) {
        $('.btn-sv').removeClass('pe-none opacity-50');
        $('.var-error').addClass('d-none');
      }
    } else {
      $('.btn-sv').addClass('pe-none opacity-50');
    }
  });

  $(document).on('click', '.editable-custom-reg', function () {
    const updatedRegVar = $(this).parents('.edit-custom-var-values').find('.edit-reg-var').val()
      .trim();
    const updatedRegVarType = $(this).parents('.edit-custom-var-values').find('.choose-var-type .active').text();
    let updatedLevelNo = $(this).parents('.edit-custom-var-values').find('.edit-levels select').val();
    let dNoneClass = '';
    if (updatedRegVarType.toLowerCase().trim() === 'numerical') {
      updatedLevelNo = '-';
      dNoneClass = 'd-none';
    } else {
      dNoneClass = '';
    }

    let newlyAddedAllowedValues = '';
    $(this).parents('.edit-custom-var-values').find('.after-edited-each-value p').each(function () {
      newlyAddedAllowedValues += `<div class="d-flex justify-content-center align-items-center 
      all-added-values"> <h6 class="level-value">${$(this).text().trim()}</h6></div>`;
    });

    if (!isEditMode) {
      $('.custom-container').children().last().remove();
      const newElement = `${`<div class="col-xl-2 col-md-3 each-var-box" data-box="${boxCount}" data-no-change="yes" data-select-initial-value="2" data-select-end-value-dropdown="20">`
                                        + '<div class="boxes-container">'
                                          + '<div class="common-boxes">'
                                            + '<div class="mb-4">'
                                              + '<h3 class="reg-var">'}${updatedRegVar}</h3>`
                                            + '</div>'
                                            + '<div class="d-flex justify-content-between align-items-center mb-4 var-level-container">'
                                              + '<div class="flex-grow-1 var-type-section">'
                                              + `<h5 class="var-type">${updatedRegVarType}</h5>`
                                              + '</div>'
                                              + '<div class="flex-grow-1 level-no-section">'
                                                + `<h5 class="level-no">${updatedLevelNo}</h5>`
                                              + '</div>'
                                          + '</div>      '
                                          + '<div>'
                                              + '<input type="number" class="total-reg" name="" id="" value="1">'
                                            + '</div>'
                                            + `<div class="mb-2 allowed-values-details ${dNoneClass}">`
                                              + '<h5 class="level-name">Allowed values</h5>'
                                              + `<div class="allowed-values">${newlyAddedAllowedValues
                                              }</div>`
                                            + '</div>'
                                          + '</div>  '
                                          + '<div class="position-absolute top-0 end-0 mt-3 me-5 delete-box" data-toggle="modal" data-target="#customVariablesDelete">'
                                            + '<a class="manage-delete-link" href="#" data-toggle="tooltipInputTooltip" title="Delete"></a>'
                                          + '</div>'
                                          + '<div class="position-absolute top-0 end-0 mt-3 me-3 edit-var" data-toggle="modal" data-target="#customVariablesEdit">'
                                            + '<a class="table-edit" data-toggle="tooltipInputTooltip" title="Edit"></a>'
                                        + '</div>'
                                          + '<div class="position-absolute start-50 bottom-0 translate-middle-x text-center mb-1 toggle-arrows">'
                                            + `<img src="${upArrowPath}">`
                                          + '</div>'
                                        + '</div>'
                                      + '</div><div class="col-lg-2 col-sm-3">'
                                        + '<div class="my-3 d-inline-block plus-btn" data-toggle="modal" data-target="#customVariablesEdit">'
                                          + '<a class="add-small-org-btn" data-toggle="tooltipInputTooltip" title="Add custom variables">+</a>'
                                        + '</div>'
                                      + '</div>';
      boxCount += 1;

      $('.add-custom-var').append(newElement);
    } else {
      if (updatedRegVarType.toLowerCase().trim() === 'numerical') {
        updatedLevelNo = '-';
        $(takeEditSession).parents('.boxes-container').find('.allowed-values-details').addClass('d-none');
      } else {
        $(takeEditSession).parents('.boxes-container').find('.allowed-values-details').removeClass('d-none');
      }
      $(takeEditSession).parents('.boxes-container').find('.reg-var').text(updatedRegVar);
      $(takeEditSession).parents('.boxes-container').find('.var-type').text(updatedRegVarType);
      $(takeEditSession).parents('.boxes-container').find('.level-no').text(updatedLevelNo);
      $(takeEditSession).parents('.boxes-container').find('.allowed-values').html(newlyAddedAllowedValues);
    }
    calculateEachRegValueAfterEditSave();
    calculateFinalRegVal();
    checkForPlusEnabled();
    triggerEditPopup();
  });

  let deleteThis = '';
  $(document).on('click', '.delete-box', function () {
    deleteThis = this;
  });

  $(document).on('click', '.delete-cus', () => {
    $(deleteThis).parents('.boxes-container').parent().remove();
    checkForPlusEnabled();
    updateSaveBtnLastPopup();
    $('.each-var-box').each(function (index) {
      const $this = $(this);
      $this.attr('data-box', index + 1);
      boxCount = index + 1;
      boxCount += 1;
    });
  });

  const defaultstaticStructure = '<div class="col-xl-2 col-md-3 each-var-box" data-box="1" data-no-change="no" data-select-initial-value="0" data-select-end-value-dropdown="0">'
                                      + '<div class="boxes-container">'
                                        + '<div class="common-boxes">'
                                          + '<div class="mb-4">'
                                            + '<h3 class="reg-var">Gender</h3>'
                                          + '</div>'
                                          + '<div class="d-flex justify-content-between align-items-center mb-4 var-level-container">'
                                            + '<div class="flex-grow-1 var-type-section">'
                                             + '<h5 class="var-type">Categorical</h5>'
                                            + '</div>'
                                            + '<div class="flex-grow-1 level-no-section">'
                                              + '<h5 class="level-no">2</h5>'
                                            + '</div>'
                                          + '</div>'
                                          + '<div>'
                                            + '<input type="number" class="total-reg" name="" id="" value="1">'
                                          + '</div>   '
                                          + '<div class="mb-2">'
                                            + '<h5 class="level-name">Allowed values</h5>'
                                            + '<div class="allowed-values">'
                                              + '<div class="d-flex justify-content-center align-items-center all-added-values">'
                                                + '<h6 class="level-value">M</h6>'
                                              + '</div>'
                                              + '<div class="d-flex justify-content-center align-items-center all-added-values">'
                                                + '<h6 class="level-value">F</h6>'
                                              + '</div>'
                                            + '</div>'
                                          + '</div>'
                                       + '</div> '
                                        + '<div class="position-absolute start-50 bottom-0 translate-middle-x text-center mb-1 toggle-arrows">'
                                          + `<img src="${upArrowPath}">`
                                        + '</div>'
                                      + '</div>   '
                                    + '</div>'
                                    + '<div class="col-xl-2 col-md-3 each-var-box" data-box="2" data-no-change="no" data-select-initial-value="0" data-select-end-value-dropdown="0">'
                                      + '<div class="boxes-container">'
                                        + '<div class="common-boxes">'
                                          + '<div class="mb-4">'
                                            + '<h3 class="reg-var">Age</h3>'
                                          + '</div>'
                                          + '<div class="d-flex justify-content-between align-items-center mb-4 var-level-container">'
                                            + '<div class="flex-grow-1  var-type-section">'
                                             + '<h5 class="var-type">Numerical</h5>'
                                            + '</div>'
                                            + '<div class="flex-grow-1  level-no-section">'
                                              + '<h5 class="level-no">-</h5>'
                                            + '</div>'
                                          + '</div>  '
                                          + '<div>'
                                            + '<input type="number" class="total-reg" name="" id="" value="2">'
                                          + '</div>                     '
                                          + '<div class="mb-2 invisible">'
                                            + '<h5 class="level-name">Allowed values </h5>'
                                            + '<div class="allowed-values">'
                                            + '</div>'
                                          + '</div>'
                                        + '</div>  '
                                        + '<div class="position-absolute start-50 bottom-0 translate-middle-x text-center mb-1 toggle-arrows">'
                                          + `<img src="${upArrowPath}">`
                                        + '</div>'
                                      + '</div>   '
                                    + '</div>'
                                    + '<div class="col-xl-2 col-md-3 each-var-box" data-box="3" data-no-change="no" data-select-initial-value="0" data-select-end-value-dropdown="0">'
                                      + '<div class="boxes-container">'
                                        + '<div class="common-boxes">'
                                          + '<div class="mb-4">'
                                            + '<h3 class="reg-var">Tenure</h3>'
                                          + '</div>'
                                          + '<div class="d-flex justify-content-between align-items-center mb-4 var-level-container">'
                                            + '<div class="flex-grow-1  var-type-section">'
                                             + '<h5 class="var-type">Numerical</h5>'
                                            + '</div>'
                                            + '<div class="flex-grow-1  level-no-section">'
                                              + '<h5 class="level-no">-</h5>'
                                            + '</div>'
                                          + '</div> '
                                          + '<div>'
                                            + '<input type="number" class="total-reg" name="" id="" value="1">'
                                          + '</div>               '
                                          + '<div class="mb-2 invisible">'
                                            + '<h5 class="level-name">Allowed values</h5>'
                                            + '<div class="allowed-values">'
                                            + '</div>'
                                          + '</div>'
                                        + '</div>  '
                                        + '<div class="position-absolute start-50 bottom-0 translate-middle-x text-center mb-1 toggle-arrows">'
                                          + `<img src="${upArrowPath}">`
                                        + '</div>'
                                      + '</div>      '
                                    + '</div>'
                                    + '<div class="col-xl-2 col-md-3 each-var-box" data-box="4" data-no-change="no" data-select-initial-value="2" data-select-end-value-dropdown="5">'
                                      + '<div class="boxes-container">'
                                        + '<div class="common-boxes">'
                                          + '<div class="mb-4">'
                                            + '<h3 class="reg-var">Responsibility of the role</h3>'
                                          + '</div>'
                                          + '<div class="d-flex justify-content-between align-items-center mb-4 var-level-container">'
                                            + '<div class="flex-grow-1 var-type-section">'
                                             + '<h5 class="var-type">Categorical</h5>'
                                            + '</div>'
                                            + '<div class="flex-grow-1 level-no-section">'
                                              + '<h5 class="level-no">2</h5>'
                                            + '</div> '
                                          + '</div>'
                                          + '<div>'
                                            + '<input type="number" class="total-reg" name="" id="" value="1">'
                                          + '</div>              '
                                          + '<div class="mb-2">'
                                            + '<h5 class="level-name">Allowed values</h5>'
                                            + '<div class="allowed-values">'
                                              + '<div class="d-flex justify-content-center align-items-center all-added-values">'
                                                + '<h6 class="level-value">IC</h6>'
                                              + '</div>'
                                              + '<div class="d-flex justify-content-center align-items-center all-added-values">'
                                                + '<h6 class="level-value">PM</h6>'
                                              + '</div>'
                                            + '</div>'
                                          + '</div>'
                                        + '</div> '
                                        + '<div class="position-absolute top-0 end-0 mt-3 me-3 edit-var" data-toggle="modal" data-target="#customVariablesEdit">'
                                          + '<a class="table-edit" data-toggle="tooltipInputTooltip" title="Edit"></a>'
                                        + '</div> '
                                        + '<div class="position-absolute start-50 bottom-0 translate-middle-x text-center mb-1 toggle-arrows">'
                                          + `<img src="${upArrowPath}">`
                                        + '</div>'
                                     + '</div>   '
                                    + '</div>'
                                    + '<div class="col-xl-2 col-md-3 each-var-box" data-box="5" data-no-change="no" data-select-initial-value="2" data-select-end-value-dropdown="5">'
                                      + '<div class="boxes-container">'
                                        + '<div class="common-boxes">'
                                          + '<div class="mb-4">'
                                            + '<h3 class="reg-var">Type of performed function</h3>'
                                          + '</div>'
                                          + '<div class="d-flex justify-content-between align-items-center mb-4 var-level-container">'
                                            + '<div class="flex-grow-1 var-type-section">'
                                             + '<h5 class="var-type ">Categorical</h5>'
                                            + '</div>'
                                            + '<div class="flex-grow-1 level-no-section">'
                                              + '<h5 class="level-no">2</h5>'
                                            + '</div>  '
                                          + '</div>  '
                                          + '<div>'
                                            + '<input type="number" class="total-reg" name="" id="" value="1">'
                                          + '</div>                   '
                                          + '<div class="mb-2">'
                                            + '<h5 class="level-name">Allowed values</h5>'
                                            + '<div class="allowed-values">'
                                              + '<div class="d-flex justify-content-center align-items-center all-added-values">'
                                                + '<h6 class="level-value">SF</h6>'
                                              + '</div>'
                                              + '<div class="d-flex justify-content-center align-items-center all-added-values">'
                                                + '<h6 class="level-value">CF</h6>'
                                              + '</div>'
                                            + '</div>'
                                          + '</div>'
                                        + '</div> '
                                        + '<div class="position-absolute top-0 end-0 mt-3 me-3 edit-var" data-toggle="modal" data-target="#customVariablesEdit">'
                                          + '<a class="table-edit" data-toggle="tooltipInputTooltip" title="Edit"></a>'
                                        + '</div> '
                                        + '<div class="position-absolute start-50 bottom-0 translate-middle-x text-center mb-1 toggle-arrows">'
                                          + `<img src="${upArrowPath}">`
                                        + '</div>'
                                      + '</div>   '
                                    + '</div>'
                                    + '<div class="col-xl-2 col-md-3 each-var-box" data-box="6" data-no-change="no" data-select-initial-value="5" data-select-end-value-dropdown="20">'
                                      + '<div class="boxes-container">'
                                        + '<div class="common-boxes">'
                                          + '<div class="mb-4">'
                                            + '<h3 class="reg-var">Level of responsibility</h3>'
                                          + '</div>'
                                          + '<div class="d-flex justify-content-between align-items-center mb-4 var-level-container">'
                                            + '<div class="flex-grow-1 var-type-section">'
                                             + '<h5 class="var-type">Categorical</h5>'
                                            + '</div>'
                                            + '<div class="flex-grow-1 level-no-section">'
                                              + '<h5 class="level-no">5</h5>'
                                            + '</div> '
                                          + '</div>'
                                          + '<div>'
                                            + '<input type="number" class="total-reg" name="" id="" value="1">'
                                          + '</div>              '
                                          + '<div class="mb-2">'
                                            + '<h5 class="level-name">Allowed values</h5>'
                                            + '<div class="allowed-values">'
                                              + '<div class="d-flex justify-content-center align-items-center all-added-values">'
                                                + '<h6 class="level-value">Operational</h6>'
                                              + '</div>'
                                              + '<div class="d-flex justify-content-center align-items-center all-added-values">'
                                                + '<h6 class="level-value">Junior</h6>'
                                              + '</div>'
                                              + '<div class="d-flex justify-content-center align-items-center all-added-values">'
                                                + '<h6 class="level-value">Middle</h6>'
                                              + '</div>'
                                              + '<div class="d-flex justify-content-center align-items-center all-added-values">'
                                                + '<h6 class="level-value">Upper</h6>'
                                              + '</div>'
                                              + '<div class="d-flex justify-content-center align-items-center all-added-values">'
                                                + '<h6 class="level-value">Top</h6>'
                                              + '</div>'
                                            + '</div>'
                                          + '</div>'
                                        + '</div> '
                                        + '<div class="position-absolute top-0 end-0 mt-3 me-3 edit-var" data-toggle="modal" data-target="#customVariablesEdit">'
                                          + '<a class="table-edit" data-toggle="tooltipInputTooltip" title="Edit"></a>'
                                        + '</div>'
                                        + '<div class="position-absolute start-50 bottom-0 translate-middle-x text-center mb-1 toggle-arrows">'
                                          + `<img src="${upArrowPath}">`
                                        + '</div>'
                                      + '</div>'
                                    + '</div>';
  // plus-box
  const defaultCustomAddPlus = '<div class="col-lg-2 col-sm-3">'
  + '<div class="my-3 d-inline-block plus-btn" data-toggle="modal" data-target="#customVariablesEdit">'
    + '<a class="add-small-org-btn" data-toggle="tooltipInputTooltip" title="Add custom variables">+</a>'
  + '</div>'
  + '</div>';

  function checkForDisableEditDelete() {
    let eachboxCountToDisable = 0;
    $('.each-var-box').each(function () {
      if (eachboxCountToDisable < 3) {
        $(this).find('.edit-var').addClass('d-none');
        $(this).find('.delete-box').addClass('d-none');
      } else if (eachboxCountToDisable >= 3 && eachboxCountToDisable < 6) {
        $(this).find('.delete-box').addClass('d-none');
      }
      eachboxCountToDisable += 1;
    });
  }

  let execute = true;
  $('.btn-cus-var').on('click', () => {
    $('#customVariables').addClass('d-inline-block show');
    if (execute !== false) {
      if ($('.cv_json_data').attr('data-dup') === 'true') {
        $('.cus-main-message p').html('The imported data is not in the required format.<br> Please manually enter the set of customized regression variables making sure that the format is EDGE-compliant and click on “Proceed” to save the data.');
        $('.custom-container.static-boxes').empty();
        $('.custom-container.add-custom-var').empty();
        $('.custom-container.static-boxes').append(defaultstaticStructure);
        $('.custom-container.add-custom-var').append(defaultCustomAddPlus);
        checkForDisableEditDelete();
        execute = false;
        $('.cus-main-message').removeClass('d-none');
      } else if (parseInt($('.total-reg-values').val(), 10) > 20) {
        $('.cus-main-message p').html('The imported data is not in the required format.<br> Please manually enter the set of customized regression variables making sure that the format is EDGE-compliant and click on “Proceed” to save the data.');
        $('.custom-container.static-boxes').empty();
        $('.custom-container.add-custom-var').empty();
        $('.custom-container.static-boxes').append(defaultstaticStructure);
        $('.custom-container.add-custom-var').append(defaultCustomAddPlus);
        checkForDisableEditDelete();
        execute = false;
        $('.cus-main-message').removeClass('d-none');
        $('.total-reg-values').val(0);
        $('.save-var-data').removeClass('pe-none opacity-50');
      } else {
        $('.cus-main-message').addClass('d-none');
      }
    }
  });

  function executePopup(obj) {
    boxCount = 1;
    $('.custom-container.static-boxes').empty();
    $('.custom-container.add-custom-var').empty();

    const staticValidArray = ['gender', 'age', 'tenure', 'responsibility of the role', 'type of performed function', 'level of responsibility'];
    let j = 0;
    execute = true;

    $('.cus-main-message p').html('The imported data is not in the required format.<br> Please manually enter the set of customized regression variables making sure that the format is EDGE-compliant and click on “Proceed” to save the data.');
    $('.cus-main-message').addClass('d-none');

    // Get an array of keys from the object
    const keys = Object.keys(obj);
    keys.forEach((keySearch) => {
      if (execute === false) {
        $('.cus-main-message').removeClass('d-none');
        return false;
      }
      for (let i = j; i < staticValidArray.length; i += 1) {
        if (keySearch.toLowerCase().trim() !== staticValidArray[j]) {
          $('.custom-container.static-boxes').append(defaultstaticStructure);
          $('.custom-container.add-custom-var').append(defaultCustomAddPlus);
          execute = false;
          return false; // Explicit return
        }

        if (keySearch.toLowerCase().trim() === 'gender') {
          if (obj[keySearch].length !== 2) {
            $('.custom-container.static-boxes').append(defaultstaticStructure);
            $('.custom-container.add-custom-var').append(defaultCustomAddPlus);
            execute = false;
            return false; // Explicit return
          }
        } else if (keySearch.toLowerCase().trim() === 'age' || keySearch.toLowerCase().trim() === 'tenure') {
          if (obj[keySearch].length !== 0) {
            $('.custom-container.static-boxes').append(defaultstaticStructure);
            $('.custom-container.add-custom-var').append(defaultCustomAddPlus);
            execute = false;
            return false; // Explicit return
          }
        }

        if (keySearch.toLowerCase().trim() === 'type of performed function'
        || keySearch.toLowerCase().trim() === 'responsibility of the role') {
          if (obj[keySearch].length < 2 || obj[keySearch].length > 5) {
            $('.custom-container.static-boxes').append(defaultstaticStructure);
            $('.custom-container.add-custom-var').append(defaultCustomAddPlus);
            execute = false;
            return false; // Explicit return
          }
        } else if (keySearch.toLowerCase().trim() === 'level of responsibility') {
          if (obj[keySearch].length < 5 || obj[keySearch].length > 20) {
            $('.custom-container.static-boxes').append(defaultstaticStructure);
            $('.custom-container.add-custom-var').append(defaultCustomAddPlus);
            execute = false;
            return false; // Explicit return
          }
        }
        j += 1;

        break;
      }
      const keyCount = Object.keys(obj).length;
      if (keyCount > 6) {
        if (obj[keySearch].length > 20) {
          $('.custom-container.static-boxes').append(defaultstaticStructure);
          $('.custom-container.add-custom-var').append(defaultCustomAddPlus);
          checkForDisableEditDelete();
          execute = false;
          return false;
        }
      }


      return true;
    });


    if (execute === false) {
      $('.cus-main-message').removeClass('d-none');
      return false;
    }


    if (execute === true) {
      let regCount = 0;
      let allowVarText = '';
      let allowedValueStatus = '';
      let globalRegCount = 0;
      let plusBtnSection = '';

      const keys1 = Object.keys(obj);
      keys1.forEach((key) => {
        if (Object.hasOwnProperty.call(obj, key)) {
          let allowVarCount = 0;
          let categoryTypeText = '';
          let alreadyAllowedValues = '';
          let limitThisAllowedVariables = 0;
          obj[key].forEach((value) => {
            allowVarCount += 1;
            if (key.toLowerCase().trim() === 'gender') {
              alreadyAllowedValues += `${'<div class="d-flex justify-content-center align-items-center all-added-values">'
                                      + '<h6 class="level-value">'}${value}</h6>`
                                    + '</div>';
              limitThisAllowedVariables += 1;
              if (limitThisAllowedVariables >= 2) {
                return false;
              }
            } else if (key.toLowerCase().trim() === 'age') {
              alreadyAllowedValues += `${'<div class="d-flex justify-content-center align-items-center all-added-values">'
                                      + '<h6 class="level-value">'}${value}</h6>`
                                      + '</div>';
              limitThisAllowedVariables += 1;
              if (limitThisAllowedVariables === 0) {
                return false;
              }
            } else if (key.toLowerCase().trim() === 'tenure') {
              alreadyAllowedValues += `${'<div class="d-flex justify-content-center align-items-center all-added-values">'
                                      + '<h6 class="level-value">'}${value}</h6>`
                                      + '</div>';
              limitThisAllowedVariables += 1;
              if (limitThisAllowedVariables === 0) {
                return false;
              }
            } else if (key.toLowerCase().trim() === 'type of performed function') {
              alreadyAllowedValues += `${'<div class="d-flex justify-content-center align-items-center all-added-values">'
                                      + '<h6 class="level-value">'}${value}</h6>`
                                      + '</div>';
              limitThisAllowedVariables += 1;
              if (limitThisAllowedVariables >= 5) {
                return false;
              }
            } else if (key.toLowerCase().trim() === 'responsibility of the role' && limitThisAllowedVariables < 5) {
              alreadyAllowedValues += `${'<div class="d-flex justify-content-center align-items-center all-added-values">'
                                      + '<h6 class="level-value">'}${value}</h6>`
                                      + '</div>';
              limitThisAllowedVariables += 1;
              if (limitThisAllowedVariables >= 5) {
                return false;
              }
            } else if (key.toLowerCase().trim() === 'level of responsibility') {
              alreadyAllowedValues += `${'<div class="d-flex justify-content-center align-items-center all-added-values">'
                                      + '<h6 class="level-value">'}${value}</h6>`
                                      + '</div>';
              limitThisAllowedVariables += 1;
              if (limitThisAllowedVariables >= 20) {
                return false;
              }
            } else {
              alreadyAllowedValues += `${'<div class="d-flex justify-content-center align-items-center all-added-values">'
                                      + '<h6 class="level-value">'}${value}</h6>`
                                      + '</div>';
              limitThisAllowedVariables += 1;
              if (limitThisAllowedVariables >= 20) {
                return false;
              }
            }
            return true;
          });

          if (allowVarCount === 0) {
            allowVarText = '-';
            categoryTypeText = 'Numerical';
            allowedValueStatus = 'd-none';
          } else {
            allowVarText = allowVarCount;
            categoryTypeText = 'Categorical';
            allowedValueStatus = '';
          }

          let selectOptionInitialValue = 0;
          let selectOptionLastValue = 0;
          let changeVarType = 'no';

          const keyValue = key.toLowerCase().trim();
          if (keyValue === 'type of performed function') {
            selectOptionInitialValue = 2;
            selectOptionLastValue = 5;
          } else if (keyValue === 'responsibility of the role') {
            selectOptionInitialValue = 2;
            selectOptionLastValue = 5;
          } else if (keyValue === 'level of responsibility') {
            selectOptionInitialValue = 5;
            selectOptionLastValue = 20;
          } else {
            selectOptionInitialValue = 2;
            selectOptionLastValue = 20;
            changeVarType = 'yes';
          }


          const varTypeLevelNo = `${'<div class="d-flex justify-content-between align-items-center mb-4 var-level-container">'
        + '<div class="flex-grow-1 var-type-section">'
        + '<h5 class="var-type ">'}${categoryTypeText}</h5>`
        + '</div>'
        + '<div class="flex-grow-1 level-no-section">'
          + `<h5 class="level-no">${allowVarText}</h5>`
        + '</div>'
      + '</div>';
          const staticReg = ` <div class="col-xl-2 col-md-3 each-var-box"  data-box="${boxCount}" data-no-change="${changeVarType}" data-select-initial-value="${selectOptionInitialValue}" data-select-end-value-dropdown="${selectOptionLastValue}">`
                                        + '<div class="boxes-container">'
                                          + '<div class="common-boxes">'
                                            + '<div class="mb-4">'
                                              + `<h3 class="reg-var">${key}</h3>`
                                            + `</div>${
                                              varTypeLevelNo
                                            }<div>`
                                              + '<input type="number" class="total-reg" name="" id="" value="1">'
                                            + '</div>'
                                            + `<div class="mb-2 allowed-values-details ${allowedValueStatus}">`
                                              + '<h5 class="level-name">Allowed values</h5>'
                                              + `<div class="allowed-values">${alreadyAllowedValues
                                              }</div>`
                                            + '</div>'
                                          + '</div>'
                                          + '<div class="position-absolute top-0 end-0 mt-3 me-3 edit-var" data-toggle="modal" data-target="#customVariablesEdit">'
                                            + '<a class="table-edit" data-toggle="tooltipInputTooltip" title="Edit"></a>'
                                          + '</div>'
                                          + '<div class="position-absolute top-0 end-0 mt-3 me-5 delete-box" data-toggle="modal" data-target="#customVariablesDelete">'
                                            + '<a class="manage-delete-link" href="#" data-toggle="tooltipInputTooltip" title="Delete"></a>'
                                          + '</div>'
                                          + '<div class="position-absolute start-50 bottom-0 translate-middle-x text-center mb-1 toggle-arrows">'
                                            + `<img src="${upArrowPath}">`
                                          + '</div>'
                                        + '</div>'
                                      + '</div>';


          regCount += 1;

          plusBtnSection = '<div class="col-lg-2 col-sm-3">'
                                          + '<div class="my-3 d-inline-block plus-btn" data-toggle="modal" data-target="#customVariablesEdit">'
                                            + '<a class="add-small-org-btn" data-toggle="tooltipInputTooltip" title="Add custom variables">+</a>'
                                          + '</div>'
                                      + '</div>';

          boxCount += 1;
          if (regCount <= 6) {
            $('.custom-container.static-boxes').append(staticReg);
          } else {
            $('.custom-container.add-custom-var').children().last().remove();
            $('.custom-container.add-custom-var').append(staticReg + plusBtnSection);
          }
          globalRegCount = regCount;
          checkStatusType();
        }
      });

      if (globalRegCount <= 6) {
        $('.custom-container.add-custom-var').append(plusBtnSection);
      }
      $('#customVariablesEdit .modal-title').text('Edit variable');
      calculateEachRegValueAfterEditSave();
      checkForPlusEnabled();
      updateSaveBtnLastPopup();
    }
    if (parseInt($('.cust-reg-var').val(), 10) !== parseInt($('.total-reg-values').val(), 10)) {
      $('.cus-main-message p').html('Please note that the value entered in the "Additional predictors included in the customized EDGE-compliant regression analysis" field is not valid.<br> The value has been automatically corrected according to the selected set of customized regression variables. To save the imported data, click on "Proceed".');
      checkForDisableEditDelete();
      execute = false;
      $('.cus-main-message').removeClass('d-none');
      return false;
    }

    checkForDisableEditDelete();
    execute = true;
    triggerEditPopup();
    return true;
  }

  $('.discard-popup .add-small-org-btn').on('click', () => {
    $('#customVariables').removeClass('d-inline-block show');
    if (Object.keys(allDataUpdateObj).length !== 0) {
      executePopup(allDataUpdateObj);
    } else if (Object.keys(ObjCustom).length !== 0) {
      executePopup(ObjCustom);
    } else if ($('#dynamic-textarea').val().trim() === '') {
      $('.total-reg-values').val(0);
      $('.custom-container.static-boxes').empty();
      $('.custom-container.add-custom-var').empty();
      $('.custom-container.static-boxes').append(defaultstaticStructure);
      $('.custom-container.add-custom-var').append(defaultCustomAddPlus);
    }
  });

  $('#close-cus-popup').on('click', () => {
    $('#customVariables').removeClass('d-inline-block show');
    if (Object.keys(allDataUpdateObj).length !== 0) {
      executePopup(allDataUpdateObj);
    } else if (Object.keys(ObjCustom).length !== 0) {
      executePopup(ObjCustom);
    } else if ($('#dynamic-textarea').val().trim() === '') {
      $('.total-reg-values').val(0);
      $('.custom-container.static-boxes').empty();
      $('.custom-container.add-custom-var').empty();
      $('.custom-container.static-boxes').append(defaultstaticStructure);
      $('.custom-container.add-custom-var').append(defaultCustomAddPlus);
    }
  });

  const dynamicTextarea = $('#dynamic-textarea');
  if (dynamicTextarea.length && dynamicTextarea.val().trim() !== '') {
    const jsonObject = $('.cv_json_data').attr('data-cv_data');
    ObjCustom = $.parseJSON(jsonObject);
    if (allDataUpdateObj != null && Object.keys(allDataUpdateObj).length > 0) {
      // Declare obj with let or var to allow reassignment
      const obj = Object.assign({}, allDataUpdateObj);
    }
    executePopup(ObjCustom);
  } else {
    const jsonObject = '{"Gender": ["M", "F"],"Age": [],"Tenure": [],"Responsibility of the role": ["IC", "PM"],"Type of Performed Function": ["SF", "CF"],"Level of responsibility": ["Operational", "Junior", "Middle", "Upper", "Top"]}';
    ObjCustom = JSON.parse(jsonObject);
    if (allDataUpdateObj != null && Object.keys(allDataUpdateObj).length > 0) {
      // Declare obj with let or var to allow reassignment
      const obj = Object.assign({}, allDataUpdateObj);
    }
    $('.cust-reg-var').val(0);
    executePopup(ObjCustom);
  }


  $(document).on('click', '.toggle-arrows', function () {
    $(this).parents('.boxes-container').toggleClass('ht');
    $(this).parents('.boxes-container').find('.reg-var').toggleClass('ellipsis');
  });

  $('.save-var-data').on('click', () => {
    const dataArray = [];
    allDataUpdateObj = {};
    $('.custom-boxes .each-var-box').each(function () {
      const eachValue = [];
      const regVar = $(this).find('.reg-var').text().trim();
      const regType = $(this).find('.var-type').text().trim();
      const regLevelNo = $(this).find('.level-no').text().trim();
      let allowedValues = '';
      if ($(this).find('.level-no').text() !== '-') {
        allowedValues = $(this).find('.allowed-values .level-value').map(function () {
          eachValue.push($(this).text().trim());
          return $(this).text().trim();
        }).get()
          .join(', ');
      }

      dataArray.push({
        regVar,
        regType,
        regLevelNo,
        allowedValues,
      });

      const keyValue = regVar;
      allDataUpdateObj[keyValue] = eachValue;
    });


    let allAddValues = '';
    for (let i = 0; i < dataArray.length; i += 1) {
      if (dataArray[i].regType.toLowerCase().trim() === 'categorical') {
        allAddValues += `${dataArray[i].regVar}: ${dataArray[i].allowedValues}; `;
      } else {
        allAddValues += `${dataArray[i].regVar}; `;
      }
    }

    document.getElementById('dynamic-textarea').value = `${allAddValues}`;
    document.getElementById('hidden_variables').value = `${allAddValues}`;

    const totalRegValues = $('.total-reg-values').val();
    // Update hidden and visible fields
    document.getElementById('hidden_additional_predictors').value = totalRegValues;
    $('.cust-reg-var').val(totalRegValues);
    executePopup(allDataUpdateObj);
    $('#customVariablesSaveData').addClass('d-inline-block show');
    setTimeout(() => {
      $('#customVariables').removeClass('d-inline-block show');
      $('#customVariablesSaveData').css('display', 'none');
      $('.modal-backdrop').remove();
      $('.cv_json_data').attr('data-dup', 'false');
      $('#customVariablesSaveData').removeClass('d-inline-block show');
    }, 2000);
  });
});
