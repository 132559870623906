import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import EdgeChartColors from './chart_colors';
import EdgeChartLabelAttributes from './chart_data_label_attributes';

function externalCustomOutsideTooltip(context) {
  // Tooltip Element
  let tooltipEl = document.getElementById('chartjs-tooltip');

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<div class="custom-tooltip-box" id="custom-tooltip-box">'
        + '<span id="left-arrow"></span>'
        + '<table style="background: rgba(0,0,0,0.7); border-radius: 4px;"></table>'
        + '<span id="right-arrow"></span>'
    + '</div>';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem.lines;
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map(getBody);

    let innerHtml = '<thead>';

    titleLines.forEach((title) => {
      innerHtml
            += '<tr><th><div style="font-family: opensans-semibold; text-align:left; '
            + 'font-size: 14px; color: #fff; padding: 4px 4px 0px;">'
            + `${title}</div></th></tr>`;
    });
    innerHtml += '</thead><tbody>';

    bodyLines.forEach((body, i) => {
      const colors = tooltipModel.labelColors[i];
      const style = 'color: #fff; font-size: 14px; display: block';
      const span = '<div class="d-flex justify-content-center align-items-center" '
        + 'style="font-family: opensans-regular; padding: 0px 4px 4px;">'
        + '<span style="margin: 0px 4px 0px 0px; display: block; width: 16px; '
        + 'height:14px; border: 1px solid #fff; '
        + `background:${colors.backgroundColor}">&nbsp;</span>`
        + `<span style="${style}">${body}</span></div>`;
      innerHtml += `<tr><td>${span}</td></tr>`;
    });

    innerHtml += '</tbody>';

    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  const position = context.chart.canvas.getBoundingClientRect();
  const bodyFont = context.chart.config._config.options.plugins.datalabels.font.family;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = 'absolute';
  const screenSize = window.innerWidth - 300;

  if (screenSize >= position.left + window.pageXOffset + tooltipModel.caretX) {
    tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
    tooltipEl.style.right = 'auto';
    document.getElementById('right-arrow').style.display = 'none';
    document.getElementById('left-arrow').style.display = 'block';
  } else {
    tooltipEl.style.left = 'auto';
    tooltipEl.style.right = `${(position.right - position.left) - (tooltipModel.caretX - 60)}px`;
    document.getElementById('right-arrow').style.display = 'block';
    document.getElementById('left-arrow').style.display = 'none';
  }

  tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
  tooltipEl.style.font = bodyFont.string;
  tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`;
  tooltipEl.style.pointerEvents = 'none';
}

export function defaultNoLeftMenu(data, dataLabels, ticks, showticks) {
  const border = 65;
  const chartData = data.reverse();
  const backgroundColors = chartData.map(props => (props[0] < border ? EdgeChartColors.e_blue
    : EdgeChartColors.turquoise));
  return {
    type: 'bar',
    data: {
      labels: ticks.reverse(),
      datasets: [{
        backgroundColor: backgroundColors,
        data: chartData.map(entry => entry[0]),
        barPercentage: 1.0,
      }],
    },
    options: {
      layout: {
        padding: {
          top: 0,
          right: 50,
        },
      },
      animation: {
        onComplete: ({ chart: { canvas } }) => {
          const target = canvas.getAttribute('data-target');
          if (!target) {
            return;
          }
          setTimeout(() => {
            const image = new Image();
            image.src = canvas.toDataURL('image/png');
            document.querySelector(`#${target}`).appendChild(image);
          }, 0);
        },
      },
      indexAxis: 'y',
      plugins: {
        tooltip: {
          titleFont: {
            family: 'opensans-semibold',
            weight: 'bold',
            size: 16,
          },
          bodyFont: {
            family: 'opensans-regular',
            weight: 'normal',
            size: 16,
          },
          boxHeight: 14,
          callbacks: {
            title(context) {
              return `${context[0].label.toString().replace(/,/g, ' ')}`;
            },
            label(context) {
              return `${parseFloat(context.formattedValue).toPrecision(3)}%`;
            },
          },
        },
        legend: {
          display: false,
        },
        datalabels: {
          anchor: 'end',
          align: 'end',
          color: '#000',
          formatter(value) {
            return `${parseFloat(value).toFixed(1)}%`;
          },
          font: {
            family: 'opensans-semibold',
            size: 12,
            weight: 'bold',
          },
        },
      },
      scales: {
        x: {
          max: 100,
          display: false,
          grid: {
            display: false,
          },
        },
        y: {
          border: {
            display: false,
          },
          afterFit(scale) {
            const whichPageRendering = sessionStorage.getItem('getOfflinePage');
            scale.width = whichPageRendering === 'true' ? 120 : 20;
          },
          display: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: showticks,
            font: {
              family: 'opensans-semibold',
              size: 12,
              weight: 'bold',
            },
            color: '#000',
          },
        },
      },
    },
    plugins: [ChartDataLabels],
  };
}

export function defaultNoLeftMenuWithAnot(data, dataLabels, ticks, showTicks) {
  const border = 65;
  const chartData = data.reverse();
  const backgroundColors = chartData.map(props => (props[0] < border ? EdgeChartColors.e_blue
    : EdgeChartColors.turquoise));

  return {
    type: 'bar',
    data: {
      labels: ticks.reverse(),
      datasets: [{
        barPercentage: 1,
        backgroundColor: backgroundColors,
        data: chartData.map(entry => entry[0]),
      }],
    },
    options: {
      animation: {
        onComplete: ({ chart: { canvas } }) => {
          const target = canvas.getAttribute('data-target');
          if (!target) {
            return;
          }

          setTimeout(() => {
            const image = new Image();

            image.src = canvas.toDataURL('image/png');
            document.querySelector(`#${target}`).appendChild(image);
          }, 0);
        },
      },
      indexAxis: 'y',
      plugins: {
        annotation: {
          annotations: {
            line1: {
              type: 'line',
              scaleID: 'x',
              value: 65,
              borderColor: '#750223',
              borderWidth: 2,
              borderDash: [8, 6],
            },
            label1: {
              type: 'label',
              drawTime: 'afterDraw',
              xAdjust: 20,
              yAdjust: ctx => -(ctx.chart.chartArea.height / 2 - 26),
              xMin: 65,
              xMax: 65,
              backgroundColor: '#750223',
              position: 'end',
              color: '#fff',
              content: ['65%'],
              borderRadius: 17,
              padding: 6,
              font: {
                family: 'opensans-bold',
                weight: 'bold',
                size: 12,
              },
            },
          },
        },
        tooltip: {
          titleFont: {
            family: 'opensans-semibold',
            weight: 'bold',
            size: 16,
          },
          bodyFont: {
            family: 'opensans-regular',
            weight: 'normal',
            size: 16,
          },
          boxHeight: 14,
          callbacks: {
            title(context) {
              return `${context[0].label.toString().replace(/,/g, ' ')}`;
            },
            label(context) {
              return `${parseFloat(context.formattedValue).toPrecision(3)}%`;
            },
          },
        },
        legend: {
          display: false,
        },
        datalabels: {
          anchor: 'end',
          align: 'end',
          color: '#000',
          formatter(value) {
            return `${parseFloat(value).toFixed(1)}%`;
          },
          font: {
            family: 'opensans-semibold',
            size: 12,
            weight: 'bold',
          },
        },
      },
      scales: {
        x: {
          max: 100,
          border: {
            display: false,
          },
          display: false,
          ticks: {
            display: false,
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          border: {
            display: false,
          },
          display: showTicks,
          ticks: {
            display: showTicks,
            font: {
              family: 'opensans-semibold',
              size: 14,
              weight: 'bold',
            },
            color: '#000',
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },

      layout: {
        padding: {
          top: 0,
          left: 10,
          right: 50,
        },
      },
    },
    plugins: [ChartDataLabels],
  };
}

export function appendStackedLabel(item, data, value) {
  if (Object.prototype.hasOwnProperty.call(data.datasets[item.datasetIndex], 'hideNoEmployee')
  && (item.datasetIndex === 2 || item.datasetIndex === 5 || item.datasetIndex === 25) && value === '100.0%') {
    value = '0%';
  }
  const stackedLabel = data.datasets[item.datasetIndex].label;
  return stackedLabel ? `${stackedLabel}: ${value}` : value;
}

export function options(labelCallback) {
  labelCallback = labelCallback || (item => item.xLabel);
  return {
    title: {
      display: true,
      text: '',
    },
    scales: {
      xAxes: [{
        ticks: {
          min: 0,
          max: 100,
          callback: value => `${value}%`,
        },
      }],
      yAxes: [{
        ticks: {
          display: true,
          fontFamily: 'opensans-semibold',
          fontSize: 14,
          fontStyle: 'bold',
        },
      }],
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontFamily: 'opensans-semibold',
        fontSize: 14,
        fontStyle: 'bold',
        boxWidth: 16,
        boxHeight: 14,
      },
    },
    tooltips: {
      callbacks: {
        label: (item, data) => appendStackedLabel(item, data, `${labelCallback(item).toFixed(1)}%`),
      },
    },
    plugins: {
      labels: false,
      datalabels: {
        fontColor: 'blue',
      },
    },
  };
}

export function defaultBarOptions(customOptions) {
  const barOptions = Chart.helpers.merge(options(item => item.yLabel), customOptions || {});

  const hasCustomScalesOptions = customOptions && Object.prototype.hasOwnProperty.call(customOptions, 'scales');
  const hasCustomXAxesOptions = hasCustomScalesOptions
    && Object.prototype.hasOwnProperty.call(customOptions.scales, 'xAxes');
  const hasCustomYAxesOptions = hasCustomScalesOptions
    && Object.prototype.hasOwnProperty.call(customOptions.scales, 'yAxes');
  const scalesOptions = {
    xAxes: [Chart.helpers.merge({
      ticks: {
        autoSkip: false,
        display: true,
        padding: 1,
        fontColor: EdgeChartColors.black,
        fontFamily: 'opensans-semibold',
        fontStyle: 'bold',
        fontSize: 14,
      },
      barPercentage: 0.8,
    }, hasCustomXAxesOptions ? customOptions.scales.xAxes[0] : {})],
    yAxes: [Chart.helpers.merge({
      ticks: {
        min: 0,
        max: 100,
        callback: value => `${value}%`,
        display: false,
      },
    }, hasCustomYAxesOptions ? customOptions.scales.yAxes[0] : {})],
  };
  return Chart.helpers.merge(barOptions, { scales: scalesOptions });
}

export function defaultStackedBarOptions(displayLegend = true) {
  return defaultBarOptions({
    scales: {
      xAxes: [{
        stacked: true,
        maxBarThickness: 50 * EdgeChartLabelAttributes.renderingRatio,
      }],
      yAxes: [{
        stacked: true,
      }],
    },
    dataLabelling: {
      displayLabelInsideBar: true,
      labelColor: '#fff',
    },
    legend: {
      display: displayLegend,
      labels: {
        padding: 20,
        fontFamily: 'opensans-semibold',
        fontSize: 14,
        fontStyle: 'bold',
        boxWidth: 16,
        boxHeight: 14,
      },
    },
  });
}

export function defaultsFlexWork(data, dataLabels, ticks) {
  const colors = [EdgeChartColors.red, EdgeChartColors.turquoise_1, EdgeChartColors.maroon, '#ff0000'];
  return {
    type: 'bar',
    data: {
      labels: ticks.reverse(),
      datasets: dataLabels.map((label, i) => ({
        backgroundColor: colors[i],
        label,
        data: data[i].map(element => element[0]),
        barPercentage: 0.88,
        categoryPercentage: 0.74,
      })),
    },
    options: {
      interaction: {
        mode: 'index',
      },
      animation: {
        onComplete: ({ chart: { canvas } }) => {
          const target = canvas.getAttribute('data-target');
          if (!target) {
            return;
          }
          setTimeout(() => {
            const image = new Image();
            image.src = canvas.toDataURL('image/png');
            document.querySelector(`#${target}`).appendChild(image);
          }, 0);
        },
      },
      plugins: {
        tooltip: {
          titleFont: {
            family: 'opensans-semibold',
            weight: 'bold',
            size: 16,
          },
          bodyFont: {
            family: 'opensans-regular',
            weight: 'normal',
            size: 16,
          },
          boxHeight: 14,
          callbacks: {
            title(context) {
              return `${context[0].label.toString().replace(/,/g, ' ')}`;
            },
            label(context) {
              return `${context.dataset.label} ${parseFloat(context.formattedValue).toPrecision(3)}%`;
            },
          },
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            font: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 14,
            },
            boxWidth: 12,
            color: '#000',
          },
        },
        datalabels: {
          anchor: 'end',
          align: 'end',
          color: '#000',
          formatter(value) {
            return `${value.toFixed(0)}%`;
          },
          font: {
            family: 'opensans-semibold',
            weight: 'bold',
            size: 12,
          },
        },
      },
      indexAxis: 'y',
      layout: {
        padding: {
          right: 50,
          left: 20,
        },
      },
      scales: {
        y: {
          border: {
            display: false,
          },
          display: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: true,
            padding: 10,
            font: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 14,
            },
            color: '#000',

          },
        },
        x: {
          display: false,
          grid: {
            display: false,
            drawBorder: false,
          },
          max: 100,
        },
      },
    },
    plugins: [ChartDataLabels],
  };
}

export function defaultChartConfigurationDetails(ticks, dataLabels, data, showTicks) {
  const colors = [
    EdgeChartColors.dark_blue, EdgeChartColors.dark_red, EdgeChartColors.strong_yellow,
    EdgeChartColors.annotations.dark_green, EdgeChartColors.dark_pink, EdgeChartColors.very_dark_cyan,
    EdgeChartColors.moderate_red, EdgeChartColors.strong_orange,
    EdgeChartColors.very_dark_lime_green, EdgeChartColors.very_dark_magenta,
    EdgeChartColors.very_dark_blue, EdgeChartColors.very_dark_desaturated_orange,
    EdgeChartColors.dark_red_2, EdgeChartColors.dark_yellow,
    EdgeChartColors.dark_violet, EdgeChartColors.strong_orange_2, EdgeChartColors.lime_green,
    EdgeChartColors.dark_magenta, EdgeChartColors.dark_cyan, EdgeChartColors.dark_moderate_violet,
    EdgeChartColors.very_dark_blue_2, EdgeChartColors.moderate_red_2,
    EdgeChartColors.moderate_green, EdgeChartColors.strong_magenta, EdgeChartColors.very_dark_green,
  ];
  const customLegend = {
    id: 'customLegend',
    beforeInit(chart) {
      let html = '<ul style="margin-left: 0">';
      chart.config._config.data.datasets.forEach((ds, i) => {
        html += `${'<li style="list-style: none;">'
    + '<span style="display: inline-block;width: 20px; margin-right:10px; height: 20px;'
    + ' background-color:'}${ds.backgroundColor};">&nbsp;</span>`
    + `<span id="legend-label-${i}" style="font-size: 14px;font-family: 'opensans-semibold'">${
      Array.isArray(ds.label) ? ds.label.join('<br/>') : ds.label}</span>`
    + '</li>';
      });
      document.getElementById(`${chart.canvas.id}_legend`).innerHTML = `${html}</ul>`;
    },
  };
  return {
    type: 'bar',
    data: {
      labels: ticks.reverse(),
      datasets: dataLabels.map((label, i) => ({
        backgroundColor: colors[i],
        label,
        data: data[i].reverse().map(element => element[0]),
        barPercentage: 0.9,
        categoryPercentage: 0.7,
      })),
    },
    options: {
      responsive: false,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          titleFont: {
            family: 'opensans-semibold',
            weight: 'bold',
            size: 16,
          },
          bodyFont: {
            family: 'opensans-regular',
            weight: 'normal',
            size: 16,
          },
          boxHeight: 14,
          callbacks: {
            title(context) {
              return `${context[0].label.toString().replace(/,/g, ' ')}`;
            },
            label(context) {
              return `${parseFloat(context.formattedValue).toPrecision(3)}%`;
            },
          },
        },
        legend: {
          display: false,
        },
        datalabels: {
          anchor: 'end',
          align: 'end',
          color: '#000',
          formatter(value) {
            return `${parseFloat(value).toFixed(1)}%`;
          },
          font: {
            family: 'opensans-semibold',
            weight: 'bold',
            size: 12,
          },
        },
      },
      indexAxis: 'y',
      layout: {
        padding: {
          right: 55,
        },
      },
      scales: {
        y: {
          border: {
            display: false,
          },
          afterFit(scale) {
            if (showTicks === true) {
              scale.width = 130;
            } else {
              scale.paddingTop = 18;
              scale.paddingBottom = 16;
            }
          },
          display: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: showTicks,
            font: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 14,
            },
            color: '#000',
          },
        },
        x: {
          min: 0,
          max: 100,
          display: false,
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
    },
    plugins: [ChartDataLabels, customLegend],
  };
}

export function defaultChartConfigurationDimension(ticks, dataLabels, data) {
  const colors = [EdgeChartColors.red, EdgeChartColors.turquoise_1, EdgeChartColors.maroon, '#ff0000'];
  return {
    type: 'bar',
    data: {
      labels: ticks.reverse(),
      datasets: dataLabels.map((label, i) => ({
        backgroundColor: colors[i],
        label,
        data: data[i].reverse().map(element => element[0]),
        barPercentage: 0.88,
        categoryPercentage: 0.74,
      })),
    },
    options: {
      interaction: {
        mode: 'index',
      },
      animation: {
        onComplete: ({ chart: { canvas } }) => {
          const target = canvas.getAttribute('data-target');
          if (!target) {
            return;
          }
          setTimeout(() => {
            const image = new Image();
            image.src = canvas.toDataURL('image/png');
            document.querySelector(`#${target}`).appendChild(image);
          }, 0);
        },
      },
      plugins: {
        tooltip: {
          titleFont: {
            family: 'opensans-semibold',
            weight: 'bold',
            size: 16,
          },
          bodyFont: {
            family: 'opensans-regular',
            weight: 'normal',
            size: 16,
          },
          boxHeight: 14,
          callbacks: {
            title(context) {
              return `${context[0].label.toString().replace(/,/g, ' ')}`;
            },
            label(context) {
              return `${context.dataset.label} ${parseFloat(context.formattedValue).toPrecision(3)}%`;
            },
          },
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            font: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 14,
            },
            boxWidth: 12,
            boxHeight: 12,
            color: '#000',
          },
        },
        datalabels: {
          anchor: 'end',
          align: 'end',
          color: '#000',
          formatter(value) {
            return `${parseFloat(value).toFixed(1)}%`;
          },
          font: {
            family: 'opensans-semibold',
            weight: 'bold',
            size: 12,
          },
        },
      },
      indexAxis: 'y',
      layout: {
        padding: {
          right: 50,
          left: 0,
        },
      },
      scales: {
        x: {
          border: {
            display: false,
          },
          display: false,
          grid: {
            display: false,
            drawBorder: false,
          },
          max: 100,
        },
        y: {
          border: {
            display: false,
          },
          display: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: true,
            padding: 12,
            font: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 14,
            },
            color: '#000',
          },
        },
      },
    },
    plugins: [ChartDataLabels],
  };
}

// Chart for the Employee Survey Charts
export function defaultChartConfiguration(ticks, dataLabels, data) {
  const colors = [EdgeChartColors.red, EdgeChartColors.turquoise_1, EdgeChartColors.maroon];
  const labels = ticks.reverse();
  const ydist = 50;
  return {
    type: 'bar',
    data: {
      labels,
      datasets: dataLabels.map((label, i) => ({
        backgroundColor: colors[i],
        label,
        data: data[i].reverse().map(element => element[0]),
        barPercentage: 0.88,
        categoryPercentage: 0.74,
      })),
    },
    options: {
      interaction: {
        mode: 'index',
      },
      animation: {
        onComplete: ({ chart: { canvas } }) => {
          const target = canvas.getAttribute('data-target');
          if (!target) {
            return;
          }

          setTimeout(() => {
            const image = new Image();

            image.src = canvas.toDataURL('image/png');
            document.querySelector(`#${target}`).appendChild(image);
          }, 0);
        },
      },
      indexAxis: 'y',
      layout: {
        padding: {
          top: 20,
          right: 50,
          left: 20,
        },
      },
      hover: { mode: null },
      scales: {
        x: {
          display: false,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: true,
          },
          max: 100,
        },
        y: {
          border: {
            display: false,
          },
          afterFit(scale) {
            scale.width = 152;
          },
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: true,
            font: {
              family: 'opensans-semibold',
              style: 'bold',
              size: 14,
            },
            color: '#000',

          },
        },
      },
      clip: false,
      plugins: {
        tooltip: {
          titleFont: {
            family: 'opensans-semibold',
            weight: 'bold',
            size: 16,
          },
          bodyFont: {
            family: 'opensans-regular',
            weight: 'normal',
            size: 16,
          },
          boxHeight: 14,
          callbacks: {
            title(context) {
              return `${context[0].label.toString().replace(/,/g, ' ')}`;
            },
            label(context) {
              return `${context.dataset.label} ${parseFloat(context.formattedValue).toPrecision(3)}%`;
            },
          },
        },
        autocolors: false,
        annotation: {
          annotations: {
            line1: {
              type: 'line',
              xMin: ydist,
              xMax: ydist,
              yMax: 0.7,
              yMin: -1,
              borderColor: '#750223',
              borderWidth: 2,
              borderDash: [8, 6],
            },
            label1: {
              type: 'label',
              drawTime: 'afterDraw',
              xAdjust: 20,
              yAdjust: ctx => -(ctx.chart.chartArea.height / 2 - 6),
              xMin: ydist,
              xMax: ydist,
              backgroundColor: '#750223',
              position: 'end',
              color: '#fff',
              content: [`${ydist}%`],
              borderRadius: 17,
              padding: 6,
              font: {
                family: 'opensans-bold',
                weight: 'bold',
                size: 12,
              },
            },
          },
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            font: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 14,
            },
            color: '#000',
            boxWidth: 14,
          },
        },
        datalabels: {
          anchor: 'end',
          align: 'end',
          color: 'black',
          bordeDash: [2, 6],
          formatter(value) {
            return `${parseFloat(value).toFixed(1)}%`;
          },
          font: {
            family: 'opensans-semibold',
            size: 12,
            style: 'bold',
          },
        },


      },
    },
    plugins: [ChartDataLabels],
  };
}

export function dimensionChartConfiguration(ticks, dataLabels, data, customOptions = undefined) {
  const colors = [
    EdgeChartColors.dark_blue, EdgeChartColors.dark_red, EdgeChartColors.strong_yellow,
    EdgeChartColors.annotations.dark_green, EdgeChartColors.dark_pink,
    EdgeChartColors.very_dark_cyan, EdgeChartColors.moderate_red,
    EdgeChartColors.strong_orange, EdgeChartColors.very_dark_lime_green,
    EdgeChartColors.very_dark_magenta, EdgeChartColors.very_dark_blue,
    EdgeChartColors.very_dark_desaturated_orange, EdgeChartColors.dark_red_2, EdgeChartColors.dark_yellow,
    EdgeChartColors.dark_violet, EdgeChartColors.strong_orange_2, EdgeChartColors.lime_green,
    EdgeChartColors.dark_magenta, EdgeChartColors.dark_cyan, EdgeChartColors.dark_moderate_violet,
    EdgeChartColors.very_dark_blue_2, EdgeChartColors.moderate_red_2, EdgeChartColors.moderate_green,
    EdgeChartColors.strong_magenta, EdgeChartColors.very_dark_green,
  ];
  const labels = ticks.reverse();

  const defaultHorizontalBarOptions = {
    layout: {
      padding: {
        right: 40,
        left: 30,
      },
    },
    ...(customOptions || options()),
  };

  return {
    type: 'horizontalBar',
    data: {
      labels,
      datasets: dataLabels.map((label, i) => ({
        backgroundColor: colors[i],
        label,
        data: data[i].reverse().map(element => element[0]),
      })),
    },
    options: defaultHorizontalBarOptions,
  };
}

export function defaultStackedBarChartConfiguration(data, dataLabels, ticks, barChartPercent, categoryChartPercent) {
  const colors = [EdgeChartColors.red, EdgeChartColors.turquoise_1, EdgeChartColors.e_blue];
  return {
    type: 'bar',
    data: {
      labels: ticks.map(tick => tick.split(' ')),
      datasets: dataLabels.map((label, i) => ({
        data: data[i],
        backgroundColor: colors[i],
        label,
        barThickness: 40,
        barPercentage: barChartPercent,
        categoryPercentage: categoryChartPercent,
      })),
    },

    options: {
      animation: {
        onComplete: ({ chart: { canvas } }) => {
          const target = canvas.getAttribute('data-target');
          if (!target) {
            return;
          }

          setTimeout(() => {
            const image = new Image();

            image.src = canvas.toDataURL('image/png');
            document.querySelector(`#${target}`).appendChild(image);
          }, 0);
        },
      },
      layout: {
        padding: {
          top: 30,
        },
      },
      scales: {
        x: {
          border: {
            display: false,
          },
          display: true,
          stacked: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: true,
            font: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 14,
            },
            color: '#000',
          },
        },
        y: {
          border: {
            display: false,
          },
          display: true,
          stacked: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
            font: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 14,
            },
            color: '#000',
          },
        },
      },
      plugins: {
        tooltip: {
          titleFont: {
            family: 'opensans-semibold',
            weight: 'bold',
            size: 16,
          },
          bodyFont: {
            family: 'opensans-regular',
            weight: 'normal',
            size: 16,
          },
          boxHeight: 14,
          callbacks: {
            label(context) {
              let label;
              for (let i = 0; i < context.dataset.data.length; i += 1) {
                if (parseInt(context.dataset.data[i], 10) === 100 && context.dataset.label === 'No employees') {
                  label = 'No of employees 0%';
                  break;
                } else {
                  label = `${context.dataset.label}: ${parseFloat(context.formattedValue).toPrecision(3)}%`;
                }
              }
              return label;
            },
          },
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            font: {
              family: 'opensans-semibold',
              size: 14,
              style: 'bold',
            },
            color: '#000',
            boxWidth: 14,
            padding: 20,
          },
        },
        datalabels: {
          anchor: 'end',
          align: 'start',
          formatter(value, context) {
            if (value >= 12 && value <= 100 && context.dataset.label !== 'No employees') {
              return `${parseFloat(value).toFixed(1)}%`;
            }
            if (value === 100) {
              return '0.0%';
            }

            return '';
          },
          font: {
            size: 12,
            weight: 'bold',
            family: 'opensans-semibold',
          },
          color: '#fff',
        },
      },
    },
    plugins: [ChartDataLabels],
  };
}

export function defaultStackedBarChartPlusConfiguration(data, dataLabels, ticks, barPercentage, categoryPercentage) {
  let colors = [EdgeChartColors.red, EdgeChartColors.red1, EdgeChartColors.turquoise_1,
    EdgeChartColors.turquoise_2, EdgeChartColors.e_blue];
  if (dataLabels.includes('Non-binary')) {
    colors = [EdgeChartColors.red, EdgeChartColors.turquoise_1, EdgeChartColors.perception.p6,
      EdgeChartColors.e_blue];
  }

  return {
    type: 'bar',
    data: {
      labels: ticks.map(tick => tick.split(' ')),
      datasets: dataLabels.map((label, i) => ({
        data: data[i],
        backgroundColor: colors[i],
        label,
        barThickness: 40,
        barPercentage,
        categoryPercentage,
      })),
    },
    options: {
      layout: {
        padding: {
          top: 30,
        },
      },
      scales: {
        x: {
          border: {
            display: false,
          },
          display: true,
          stacked: true,
          ticks: {
            display: true,
            font: {
              family: 'opensans-semibold',
              weight: 'bold',
              size: 14,
            },
            color: '#000',
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          display: false,
          stacked: true,
          max: 100,
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
      plugins: {
        tooltip: {
          yAlign: 'bottom',
          titleFont: {
            family: 'opensans-semibold',
            weight: 'bold',
            size: 16,
          },
          bodyFont: {
            family: 'opensans-regular',
            weight: 'normal',
            size: 16,
          },
          boxHeight: 14,
          callbacks: {
            label(context) {
              if (context.dataset.label === 'No employees' && parseInt(context.formattedValue, 10) === 100) {
                return 'No of employee 0%';
              }

              if (context.dataset.label === 'Not applicable' && parseInt(context.formattedValue, 10) === 100) {
                return 'Not applicable';
              }

              return `${context.dataset.label}: ${parseFloat(context.formattedValue).toPrecision(3)}%`;
            },
          },
          enabled: false,
          external: externalCustomOutsideTooltip,
        },
        legend: {
          display: false,
        },
        datalabels: {
          anchor: 'end',
          align: 'start',
          formatter(value, context) {
            if (value === 100 && context.dataset.label === 'No employees') {
              return '0.0%';
            }
            if (value >= 12 && value <= 100) {
              return `${parseFloat(value).toFixed(1)}%`;
            }
            return '';
          },
          font: {
            family: 'opensans-semibold',
            size: 12,
            weight: 'bold',
          },
          color: '#fff',
        },
      },
    },
    plugins: [ChartDataLabels],
  };
}

export function defaultLineChartConfigurationMany(data, dataLabels, axisLabels, chartOptions = null) {
  const colors = [EdgeChartColors.red, EdgeChartColors.turquoise_2, EdgeChartColors.turquoise_1,
    EdgeChartColors.e_blue, EdgeChartColors.black_2, EdgeChartColors.black];
  return {
    type: 'line',
    default: {
      scale: {
        gridLines: {
          display: true,
        },
        ticks: {
          display: true,
        },
      },
    },
    data: {
      labels: axisLabels,
      datasets: dataLabels.map((label, i) => ({
        data: data[i],
        borderColor: colors[i],
        label,
        fill: false,
        tension: 0.4,
        borderWidth: Chart.defaults.global.offscreenRendering ? 10 : 4,
        fontSize: Chart.defaults.global.offscreenRendering ? 50 : 14,
      })),
    },
    options: {
      responsive: true,
      stepped: false,
      cubicInterpolationMode: 'monotone',
      title: {
        display: true,
        text: 'Projected Share of Women',
        fontSize: Chart.defaults.global.offscreenRendering ? 50 : 14,
      },
      layout: {
        padding: {
          left: 10,
          right: 30,
        },
        border: true,
        grid: true,
        axis: true,
      },
      dataLabelling: {
        displayPercent: true,
      },
      scales: {
        xAxes: [{
          display: true,
          offset: true,
          type: 'linear',
          ticks: {
            min: 0,
            max: 100,
            stepSize: 25,
            autoSkip: false,
            display: true,
            padding: 10,
            callback: value => `${value}%`,
            fontStyle: 'bold',
            fontSize: Chart.defaults.global.offscreenRendering ? 50 : 10,
          },
          barPercentage: 1,
        }],
        yAxes: [{
          type: 'category',
          display: true,
          ticks: {
            fontFamily: 'inter-regular',
            fontStyle: 'normal',
            fontSize: Chart.defaults.global.offscreenRendering ? 50 : 10,
          },
        }],
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontSize: Chart.defaults.global.offscreenRendering ? 40 : 15,
          padding: 20,
          boxWidth: 13,
          font: {
            family: 'inter-regular',
            fontStyle: 'normal',
          },
        },
      },
      ...chartOptions,
    },
  };
}

export function defaultLineChartConfiguration(data, dataLabels, axisLabels) {
  let naData = [];
  naData = JSON.parse(sessionStorage.getItem('getNAValues'));
  const colors = [EdgeChartColors.red, EdgeChartColors.turquoise_2, EdgeChartColors.turquoise_1,
    EdgeChartColors.e_blue, EdgeChartColors.black_2, EdgeChartColors.black];

  for (let index = naData.length - 1; index >= 0; index -= 1) {
    if (naData[index].includes(999)) {
      naData.splice(index, 1);
      data.splice(index, 1);
      dataLabels.splice(index, 1);
      colors.splice(index, 1);
    }
  }

  return {
    type: 'line',

    data: {
      labels: axisLabels,
      datasets: dataLabels.map((label, i) => ({
        data: data[i],
        backgroundColor: colors[i],
        borderColor: colors[i],
        label,
        fill: false,
        tension: 0.4,
        borderWidth: 4,
      })),

    },
    options: {

      animation: {
        onComplete: ({ chart: { canvas } }) => {
          const target = canvas.getAttribute('data-target');
          if (!target) {
            return;
          }
          setTimeout(() => {
            const image = new Image();
            image.src = canvas.toDataURL('image/png');
            document.querySelector(`#${target}`).appendChild(image);
          }, 0);
        },
      },
      plugins: {
        tooltip: {
          titleFont: {
            family: 'opensans-semibold',
            weight: 'bold',
            size: 16,
          },
          bodyFont: {
            family: 'opensans-regular',
            weight: 'normal',
            size: 16,
          },
          callbacks: {
            label: (context) => {
              const { dataset, dataIndex, datasetIndex } = context;
              const value = dataset.data[dataIndex];
              const valueFromTooltipData = naData[datasetIndex][dataIndex];
              const getValue = `${dataset.label}: ${parseFloat(value).toFixed(1)}`;
              const NAValue = `${dataset.label}: NA`;
              return `${valueFromTooltipData === 999 ? NAValue : getValue}`;
            },
          },
        },
        datalabels: {

          anchor(context) {
            return context.dataIndex === 0 ? 'start' : 'end';
          },
          align(context) {
            return context.dataIndex === context.dataset.data.length - 1 ? 'right' : 'left';
          },
          display: (context) => {
            if (context.dataIndex === 0) {
              return context.dataIndex === 0;
            }

            return context.dataIndex === context.dataset.data.length - 1;
          },
          formatter(value, context) {
            const { dataIndex, datasetIndex } = context;
            const valueFromDatalabelsData = naData[datasetIndex][dataIndex];
            const getValue = `${parseFloat(value).toFixed(1)}%`;
            return `${valueFromDatalabelsData === 999 ? 'NA' : getValue}`;
          },
          color: context => context.dataset.borderColor,
          font: {
            family: 'opensans-semibold',
            weight: 'bold',
            size: 14,
          },
        },
        title: {
          display: true,
          text: 'Projected Share of Women',
          font: {
            family: 'opensans-semibold',
            weight: 'bold',
            size: 14,
          },
          color: '#000',
          padding: {
            top: 10,
            bottom: 16,
          },
        },
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
          labels: {
            boxWidth: 14,
            font: {
              weight: 'normal',
              size: 15,
              family: 'opensans-regular',
            },
            color: '#000',
          },
        },
      },
      scales: {
        x: {
          display: true,
          offset: true,
          ticks: {
            padding: 20,
            display: true,
            font: {
              family: 'inter-bold',
              weight: 'bold',
              size: 10,
            },
            color: '#000',
          },
          barPercentage: 1,
        },
        y: {
          display: true,
          min: 0,
          max: 100,
          ticks: {
            stepSize: 25,
            autoSkip: false,
            font: {
              family: 'inter-regular',
              weight: 'normal',
              size: 10,
            },
            callback: value => `${value}%`,
          },
          color: '#667085',
        },
      },
    },
    plugins: [ChartDataLabels],
  };
}

export function defaultOffscreenPresentationOptions(offscreenFontSize = 15) {
  return $.extend(true, options(), !Chart.defaults.global.offscreenRendering ? {} : {
    legend: {
      labels: {
        fontSize: offscreenFontSize,
      },
    },
    dataLabelling: {
      fontSize: offscreenFontSize,
      offsetY: 10,
      offsetX: 8,
    },
    scales: {
      yAxes: [{
        ticks: {
          fontSize: offscreenFontSize,
          padding: 20,
        },
      }],
    },

    responsive: false,
    maintainAspectRatio: true,
    offscreenRendering: true,
    offscreenRenderingRatio: 1.7,

  });
}

export function lineAnnotation(orientation, value, labelValue = undefined,
  lineColor = EdgeChartColors.red1) {
  const isVertical = orientation === 'vertical';
  labelValue = labelValue || (label => `${Math.round(label)}%`);
  return {
    type: 'line',
    mode: orientation,
    scaleID: isVertical ? 'x-axis-0' : 'y-axis-0',
    borderDash: [12, 12],
    borderDashOffset: 5,
    value,
    borderColor: lineColor,
    borderWidth: 2,

    label: {
      enabled: true,
      backgroundColor: lineColor,
      fontFamily: 'sans-serif',
      fontSize: EdgeChartLabelAttributes.fontSize,
      content: labelValue(value),
      position: isVertical ? 'top' : 'right',
      cornerRadius: 50,
    },
  };
}

export function defaultEnlargedScaleOptions(offscreenFontSize = 30) {
  return {
    ticks: {
      fontSize: offscreenFontSize,
    },
  };
}

export function defaultOffscreenStackedBarOptions(displayLegend = false) {
  const chartOptions = defaultStackedBarOptions(displayLegend);
  return $.extend(true, chartOptions, {
    scales: {
      xAxes: [Chart.helpers.merge(chartOptions.scales.xAxes[0], defaultEnlargedScaleOptions())],
    },
  });
}

export function asyncChart(url, callback) {
  $.ajax({
    async: true,
    url,
    dataType: 'json',
    success: callback,
  });
}

export function setMaxmin(data) {
  let maxValue = 0;
  let minValue = 0;

  for (let i = 0; i < data.length; i += 1) {
    // calculate the max and min values of the chart
    for (let j = 0; j < data[i].length; j += 1) {
      if (data[i][j] > maxValue) {
        maxValue = data[i][j];
      }

      if (data[i][j] < minValue) {
        minValue = data[i][j];
      }
    }
  }

  // create a buffer for the max and min values
  if (minValue < 0) {
    minValue -= 5;
  }
  if (maxValue > 0) {
    maxValue += 5;
  }

  return [minValue, maxValue];
}
