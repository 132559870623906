import kcbotData from './info_hub.json';

$(document).ready(() => {
  const getAllJSonContent = kcbotData;
  const selectedGainDirectLinks = document.querySelector('.gainsight-redirects-links');
  const selectedGainDefinition = document.querySelector('.gainsight-definition');
  const searchbox = document.querySelector('.search-div');
  const gainsightmainHeader = document.querySelectorAll('.gainsight-main-selected-hdr');
  let searchEnabled = false;
  const subSelectedGainDefinition = document.querySelector('.gainsight-sub-definition');
  let posX = 0;
  let posY = 0;
  let isDragging = false;
  const draggable = document.getElementById('gainSiteJson');
  const draggableContent = document.getElementById('drag-content');
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  let divWidth = 0;
  let divHeight = 0;

  if (draggable !== null) {
    divWidth = draggable.offsetWidth;
    divHeight = draggable.offsetHeight;
  }

  function goToNeededChoiceSection() {
    selectedGainDirectLinks.style.left = '-100%';
    selectedGainDefinition.style.left = '0%';
  }

  function goToNeededChoiceSubSection() {
    selectedGainDefinition.style.left = '-100%';
    subSelectedGainDefinition.style.left = '0%';
  }

  function myCallBack(submainSecondSection) {
    if (submainSecondSection) {
      submainSecondSection.forEach((sublists) => {
        sublists.addEventListener('click', (e) => {
          searchbox.classList.add('d-none');

          gainsightmainHeader.forEach((element) => {
            element.classList.remove('d-none');
          });

          if (searchEnabled) {
            searchbox.classList.remove('d-none');
            document.querySelectorAll('.gainsight-definition .gainsight-main-selected-hdr').forEach((element) => {
              element.classList.add('d-none');
            });
          }

          const getCategoryName = sublists.innerText.trim();
          const categorynameId = sublists.getAttribute('data-gainsight-secondset');
          for (let i = 0; i < getAllJSonContent.data.length; i += 1) {
            if (getAllJSonContent.data[i].id === categorynameId
                && getAllJSonContent.data[i].data_type.toLowerCase() === 'article') {
              goToNeededChoiceSubSection();
              const selector = '.gainsight-sub-definition .gainsight-sel-sect h4';
              const content = sublists.innerText.trim();

              document.querySelector(selector).textContent = content;
              const articleTitle = getAllJSonContent.data[i].article_title_html;
              const articleBody = getAllJSonContent.data[i].article_body_html;

              const getTableStructure = `
                <div class="main-hdr-fn">
                  <h1 class="font-twofour-bold-turquoise-1">${articleTitle}</h1>
                </div>
                <div>${articleBody}</div>
              `;

              document.getElementById('sub-search-results').innerHTML = getTableStructure;
            }
          }
        });
      });
    }
  }

  function keepWithinBounds(posx, posy) {
    if (posx < 0) {
      posx = 0;
    } else if (posx + divWidth > windowWidth) {
      posx = windowWidth - divWidth;
    }

    if (posy < 0) {
      posy = 0;
    } else if (posy + divHeight > windowHeight) {
      posy = windowHeight - divHeight;
    }
    return [posx, posy];
  }

  function drag(e) {
    if (isDragging) {
      posX = e.clientX;
      posY = e.clientY;
      let counted = 0;
      if (counted === 0) {
        posX -= divWidth / 2;
        posY -= divHeight / 2;
        counted += 1;
      }

      const [newPosX, newPosY] = keepWithinBounds(posX, posY);

      draggable.style.left = `${newPosX}px`;
      draggable.style.top = `${newPosY}px`;

      if (posY < windowHeight / 2 && posX > windowWidth / 2) {
        draggableContent.classList.remove('right-side-top');
        draggableContent.classList.add('right-side-bottom');
        draggableContent.classList.remove('left-side-top');
        draggableContent.classList.remove('left-side-bottom');
      } else if (posY < windowHeight / 2 && posX < windowWidth / 2) {
        draggableContent.classList.remove('left-side-top');
        draggableContent.classList.add('left-side-bottom');
        draggableContent.classList.remove('right-side-top');
        draggableContent.classList.remove('right-side-bottom');
      } else if (posX < windowWidth / 2 && posY > windowHeight / 2) {
        draggableContent.classList.add('left-side-top');
        draggableContent.classList.remove('left-side-bottom');
        draggableContent.classList.remove('right-side-top');
        draggableContent.classList.remove('right-side-bottom');
      } else {
        draggableContent.classList.add('right-side-top');
        draggableContent.classList.remove('right-side-bottom');
        draggableContent.classList.remove('left-side-top');
        draggableContent.classList.remove('left-side-bottom');
      }
      posX = e.clientX;
      posY = e.clientY;
    }
  }

  if (document.querySelector('#gainSiteJson')) {
    const categoryName = [];
    const categoryBadge = [];
    const categoryDirectLink = [];
    const categoryId = [];
    let firstMainLists = '';
    let submaingetAllHeader = '';
    let linkGetOnlyContent = '';
    let articleGetOnlyContent = '';
    const order = getAllJSonContent.sequence.sequenceCount + 1;

    for (let i = 1; i <= order; i += 1) {
      getAllJSonContent.data.forEach((item) => {
        if (item.ih_category.ih_category_sequence === i) {
          const categoryNameSingle = item.ih_category.ih_category_name;
          const categoryNameId = item.ih_category.id;
          const categoryNameBadge = item.ih_category.ih_category_show_badge;
          const categoryNameLink = item.information_hub_link.ihlink_url;

          if (!categoryName.includes(categoryNameSingle) && item.title !== '') {
            categoryName.push(categoryNameSingle);
            categoryId.push(categoryNameId);
            categoryBadge.push(categoryNameBadge);
            categoryDirectLink.push(categoryNameLink);
          }
        }
      });
    }

    categoryName.forEach((name, i) => {
      const badge = categoryBadge[i];
      const link = categoryDirectLink[i];

      firstMainLists += `
      <div class="d-flex justify-content-between align-items-center position-relative px-4 gainsight-redirects"
           data-gainsight-firstset="${categoryId[i]}"
           data-showBadge="${badge}">
        <div class="position-absolute start-0 h-100 gainsight-hover-indicator">&nbsp;</div>
        <div class="submainListedData">
          <a class="font-onefour-regular m-0 gainsight-main-hdr" title="${name}"
             ${badge ? '' : `href="${link}" target="_blank"`}>
            ${name}
          </a>
        </div>
        <div class="">
          <img src="${categoryArrowPath}" alt="">
        </div>
      </div>
    `;
    });

    document.getElementById('gainsight-mainlink-redirect').innerHTML = firstMainLists;

    const gainsightShowHideBtn = document.querySelector('.gainsight-btn');
    const gainsightDefinition = document.querySelector('.gainsight-content');
    const gainsightImage = document.querySelectorAll('.gainsight-img-div');
    const gainsightlistings = document.querySelectorAll('.gainsight-main-selected-hdr .gainsight-main-hdr');
    const backtoMainSearchFromSearching = document.querySelectorAll('.gainsight-clk');
    const backtoSearchFromListing = document.querySelectorAll('.gainsight-clk-searching-all-lists');
    const chevronImage = document.querySelector('.gainside-chevron');
    const backtoSubSearch = document.querySelector('.gainsight-clk-sub');
    const allgainSightLinks = document.querySelectorAll('#gainsight-mainlink-redirect .gainsight-redirects');
    const searchInputText = document.querySelector('.gainsight-search');
    let submainSecondSection = '';
    const allSearchShow = document.querySelector('#all-clk');
    const allLinkShow = document.querySelector('#link-clk');
    const allArticleShow = document.querySelector('#article-clk');
    const showSearchCategory = document.querySelector('.search-category');

    if (searchInputText) {
      searchInputText.addEventListener('input', (e) => {
        goToNeededChoiceSection();
        showSearchCategory.querySelectorAll('a').forEach((a) => {
          a.classList.remove('active');
        });
        document.querySelector('#search-results').classList.add('position-adjust', 'search-adjust');
        document.querySelector('.position-adjust').classList.remove('mt-2');
        allSearchShow.querySelector('a').classList.add('active');
        let submainHeader = '';
        let linkOnlyContent = '';
        let articleOnlyContent = '';
        let linkOnlyContentCount = 0;
        let articleOnlyContentCount = 0;
        let allContentCount = 0;
        searchEnabled = true;
        selectedGainDefinition.classList.remove('h-100');
        chevronImage.classList.remove('d-none');
        showSearchCategory.classList.remove('d-none');
        gainsightmainHeader.forEach((element) => {
          element.classList.add('d-none');
        });

        gainsightlistings.forEach((element) => {
          element.classList.remove('d-none');
        });
        for (let i = 0; i < getAllJSonContent.data.length; i += 1) {
          const searchTerm = searchInputText.value.trim().toLowerCase();
          if (getAllJSonContent.data[i].data_title.trim().toLowerCase().includes(searchTerm)) {
            if (getAllJSonContent.data[i].data_type.toLowerCase() === 'link') {
              submainHeader += `
                <div class="d-flex justify-content-between align-items-center position-relative px-4 
                gainsight-redirects"
                     data-gainsight-secondset="${getAllJSonContent.data[i].id}"
                     data-showbadge="${getAllJSonContent.data[i].ih_category.ih_category_show_badge}">
                  <div class="position-absolute start-0 h-100 gainsight-hover-indicator">&nbsp;</div>
                  <div class="submainListedData">
                    <a class="font-onefour-regular m-0 gainsight-main-hdr"
                       href="${getAllJSonContent.data[i].information_hub_link.ihlink_url}"
                       target="_blank">
                      ${getAllJSonContent.data[i].data_title}
                    </a>
                  </div>
                </div>
              `;

              allContentCount += 1;

              linkOnlyContent += `
                <div class="d-flex justify-content-between align-items-center position-relative
                 px-4 gainsight-redirects"
                     data-gainsight-secondset="${getAllJSonContent.data[i].id}"
                     data-showbadge="${getAllJSonContent.data[i].ih_category.ih_category_show_badge}">
                  <div class="position-absolute start-0 h-100 gainsight-hover-indicator">&nbsp;</div>
                  <div class="submainListedData">
                    <a class="font-onefour-regular m-0 gainsight-main-hdr"
                       href="${getAllJSonContent.data[i].information_hub_link.ihlink_url}"
                       target="_blank">
                      ${getAllJSonContent.data[i].data_title}
                    </a>
                  </div>
                </div>
              `;

              linkOnlyContentCount += 1;
            } else {
              submainHeader += `
                <div class="d-flex justify-content-between align-items-center position-relative px-4 
                 gainsight-redirects"
                     data-gainsight-secondset="${getAllJSonContent.data[i].id}"
                     data-showbadge="${getAllJSonContent.data[i].ih_category.ih_category_show_badge}">
                  <div class="position-absolute start-0 h-100 gainsight-hover-indicator">&nbsp;</div>
                  <div class="submainListedData">
                    <a class="font-onefour-regular m-0 gainsight-main-hdr">
                      ${getAllJSonContent.data[i].data_title}
                    </a>
                  </div>
                  <div class="">
                    <img src="${categoryArrowPath}" alt="">
                  </div>
                </div>
              `;

              allContentCount += 1;

              articleOnlyContent += `
                <div class="d-flex justify-content-between align-items-center position-relative px-4 
                 gainsight-redirects"
                     data-gainsight-secondset="${getAllJSonContent.data[i].id}"
                     data-showbadge="${getAllJSonContent.data[i].ih_category.ih_category_show_badge}">
                  <div class="position-absolute start-0 h-100 gainsight-hover-indicator">&nbsp;</div>
                  <div class="submainListedData">
                    <a class="font-onefour-regular m-0 gainsight-main-hdr">
                      ${getAllJSonContent.data[i].data_title}
                    </a>
                  </div>
                  <div>
                    <img src="${categoryArrowPath}" alt="">
                  </div>
                </div>
              `;

              articleOnlyContentCount += 1;
            }
          }
        }
        submaingetAllHeader = submainHeader;
        linkGetOnlyContent = linkOnlyContent;
        articleGetOnlyContent = articleOnlyContent;
        document.getElementById('search-results').innerHTML = submainHeader;
        if (submainHeader === '') {
          // eslint-disable-next-line max-len
          document.getElementById('search-results').innerHTML = '<p class="font-onefour-regular py-2 m-0 text-center no-result-found">'
            + 'No results found</p>';
        }
        allSearchShow.querySelector('a').textContent = `All(${allContentCount})`;
        allLinkShow.querySelector('a').textContent = `Link(${linkOnlyContentCount})`;
        allArticleShow.querySelector('a').textContent = `Article(${articleOnlyContentCount})`;

        if (searchInputText.value.length <= 0) {
          selectedGainDirectLinks.style.left = '0%';
          selectedGainDefinition.style.left = '100%';
          chevronImage.classList.add('d-none');
        }
        submainSecondSection = document.querySelectorAll('#search-results .gainsight-redirects');
        myCallBack(submainSecondSection);
      }, myCallBack);
    }

    allSearchShow.addEventListener('click', () => {
      document.getElementById('search-results').innerHTML = submaingetAllHeader;
      if (submaingetAllHeader === '') {
        document.getElementById('search-results').innerHTML = '<p class="font-onefour-regular py-2 m-0 text-center '
         + 'no-result-found">No results found</p>';
      }
      showSearchCategory.querySelectorAll('a').forEach((a) => {
        a.classList.remove('active');
      });
      allSearchShow.querySelector('a').classList.add('active');
      submainSecondSection = document.querySelectorAll('#search-results .gainsight-redirects');
      myCallBack(submainSecondSection);
    }, myCallBack);

    allLinkShow.addEventListener('click', () => {
      document.getElementById('search-results').innerHTML = linkGetOnlyContent;
      if (linkGetOnlyContent === '') {
        document.getElementById('search-results').innerHTML = '<p class="font-onefour-regular py-2 m-0 text-center '
         + 'no-result-found">No results found</p>';
      }
      showSearchCategory.querySelectorAll('a').forEach((a) => {
        a.classList.remove('active');
      });
      allLinkShow.querySelector('a').classList.add('active');
      submainSecondSection = document.querySelectorAll('#search-results .gainsight-redirects');
      myCallBack(submainSecondSection);
    }, myCallBack);

    allArticleShow.addEventListener('click', () => {
      document.getElementById('search-results').innerHTML = articleGetOnlyContent;
      if (articleGetOnlyContent === '') {
        document.getElementById('search-results').innerHTML = '<p class="font-onefour-regular py-2 m-0 text-center '
         + 'no-result-found">No results found</p>';
      }
      showSearchCategory.querySelectorAll('a').forEach((a) => {
        a.classList.remove('active');
      });
      allArticleShow.querySelector('a').classList.add('active');
      submainSecondSection = document.querySelectorAll('#search-results .gainsight-redirects');
      myCallBack(submainSecondSection);
    }, myCallBack);

    if (gainsightShowHideBtn) {
      gainsightShowHideBtn.addEventListener('click', () => {
        if (searchEnabled === false) {
          showSearchCategory.classList.add('d-none');
        }
        gainsightImage.forEach((div) => {
          div.classList.toggle('active');
        });
        gainsightDefinition.classList.toggle('d-none');
      });
    }

    if (backtoMainSearchFromSearching) {
      backtoMainSearchFromSearching.forEach((backBtn) => {
        backBtn.addEventListener('click', () => {
          showSearchCategory.classList.add('d-none');
          selectedGainDirectLinks.style.left = '0%';
          selectedGainDefinition.style.left = '100%';
          searchbox.classList.remove('d-none');
          document.querySelector('.gainsight-search').value = '';
          chevronImage.classList.add('d-none');
        });
        searchEnabled = false;
      });
    }

    if (backtoSearchFromListing) {
      backtoSearchFromListing.forEach((backBtn) => {
        backBtn.addEventListener('click', () => {
          selectedGainDirectLinks.style.left = '0%';
          selectedGainDefinition.style.left = '100%';
          searchbox.classList.remove('d-none');
          document.querySelector('.gainsight-search').value = '';
          chevronImage.classList.add('d-none');
          searchEnabled = false;
        });
      });
    }

    if (backtoSubSearch) {
      backtoSubSearch.addEventListener('click', () => {
        selectedGainDefinition.style.left = '0%';
        subSelectedGainDefinition.style.left = '100%';
        if (searchEnabled) {
          searchbox.classList.remove('d-none');
          gainsightmainHeader.forEach((element) => {
            element.classList.add('d-none');
          });
        }
      });
    }

    if (allgainSightLinks) {
      allgainSightLinks.forEach((lists) => {
        lists.addEventListener('click', () => {
          document.querySelector('#search-results').classList.remove('position-adjust', 'search-adjust');
          document.querySelector('#search-results').classList.add('mt-2');
          if (lists.getAttribute('data-showbadge') === 'true') {
            const getAllSequenceNumberOfCategory = [];
            showSearchCategory.classList.add('d-none');
            goToNeededChoiceSection();
            gainsightmainHeader.forEach((element) => {
              element.classList.remove('d-none');
            });
            searchbox.classList.add('d-none');
            selectedGainDefinition.classList.add('h-100');
            document.querySelector('.gainsight-definition .gainsight-sel-sect h4').textContent = lists.innerText.trim();

            const getCategoryName = lists.innerText.trim();
            const categorynameId = lists.getAttribute('data-gainsight-firstset');
            let submainHeader = '';
            for (let i = 0; i < getAllJSonContent.data.length; i += 1) {
              if (getAllJSonContent.data[i].ih_category.id === categorynameId) {
                getAllSequenceNumberOfCategory.push(getAllJSonContent.data[i].display_sequence);
              }
            }
            getAllSequenceNumberOfCategory.sort((a, b) => a - b);
            for (let j = 0; j < getAllSequenceNumberOfCategory.length; j += 1) {
              for (let i = 0; i < getAllJSonContent.data.length; i += 1) {
                if (getAllJSonContent.data[i].display_sequence === getAllSequenceNumberOfCategory[j]) {
                  if (getAllJSonContent.data[i].data_type.toLowerCase() === 'link') {
                    submainHeader += `
                      <div class="d-flex justify-content-between align-items-center position-relative px-4
                       gainsight-redirects"
                           data-gainsight-secondset="${getAllJSonContent.data[i].id}">
                        <div class="position-absolute start-0 h-100 gainsight-hover-indicator">&nbsp;</div>
                        <div class="submainListedData">
                          <a class="font-onefour-regular m-0 gainsight-main-hdr"
                             href="${getAllJSonContent.data[i].information_hub_link.ihlink_url}"
                             target="_blank" title="${getAllJSonContent.data[i].data_title}">
                            ${getAllJSonContent.data[i].data_title}
                          </a>
                        </div>
                      </div>
                    `;
                  } else {
                    submainHeader += `
                      <div class="d-flex justify-content-between align-items-center position-relative px-4 
                       gainsight-redirects"
                           data-gainsight-secondset="${getAllJSonContent.data[i].id}">
                        <div class="position-absolute start-0 h-100 gainsight-hover-indicator">&nbsp;</div>
                        <div class="submainListedData">
                          <a class="font-onefour-regular m-0 gainsight-main-hdr" 
                            title="${getAllJSonContent.data[i].data_title}">
                            ${getAllJSonContent.data[i].data_title}
                          </a>                          
                        </div>
                        <div class="">
                          <img src="${categoryArrowPath}" alt="">
                        </div>
                      </div>
                    `;
                  }
                }
              }
            }
            document.getElementById('search-results').innerHTML = submainHeader;
            submainSecondSection = document.querySelectorAll('#search-results .gainsight-redirects');
            myCallBack(submainSecondSection);
          }
        }, myCallBack);
      });
    }

    draggable.querySelector('#draggable').addEventListener('mousedown', (e) => {
      isDragging = true;
      document.addEventListener('mousemove', drag);
      document.addEventListener('mouseup', () => {
        isDragging = false;
        document.removeEventListener('mousemove', drag);
      });
    });

    const expandDialogBox = document.querySelector('#gainsight-expand');
    const expandDialogBoxWindow = document.querySelector('.gainsight-content');
    let isExpand = false;

    expandDialogBox.addEventListener('click', () => {
      if (isExpand === true) {
        expandDialogBoxWindow.style.width = '';
        expandDialogBox.src = expandDialogBoxSrc;
      } else {
        const mediaQuery = window.innerWidth;
        if (mediaQuery > 1440) {
          expandDialogBoxWindow.style.width = '950px';
        } else if (mediaQuery > 1011) {
          expandDialogBoxWindow.style.width = '700px';
        } else {
          expandDialogBoxWindow.style.width = '600px';
        }
      }
      isExpand = !isExpand;
    });
  }
});
